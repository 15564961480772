// @ts-nocheck
import L from 'leaflet'
import 'Leaflet-WMS'

async function fetchImage(url, callback, headers, abort) {
  const _headers = {}
  if (headers) {
    headers.forEach((h) => {
      _headers[h.header] = h.value
    })
  }
  const controller = new AbortController()
  const signal = controller.signal
  if (abort) {
    abort.subscribe(() => {
      controller.abort()
    })
  }

  try {
    const f = await fetch(url, {
      method: 'GET',
      headers: _headers,
      mode: 'cors',
      signal,
    })
    const blob = await f.blob()
    callback(blob)
  } catch (error) {
    //
  }
}

L.TileLayer.WMSHeader = L.TileLayer.WMS.extend({
  initialize(url, options, headers, abort) {
    L.TileLayer.WMS.prototype.initialize.call(this, url, options)
    this.headers = headers
    this.abort = abort
  },
  createTile(coords, done) {
    const url = this.getTileUrl(coords)
    const img = document.createElement('img')
    img.setAttribute('role', 'presentation')

    fetchImage(
      url,
      (resp) => {
        const reader = new FileReader()
        reader.onload = () => {
          img.src = reader.result
        }
        reader.readAsDataURL(resp)
        done(null, img)
      },
      this.headers,
      this.abort
    )
    return img
  },
})

L.TileLayer.wmsHeader = function (url, options, headers, abort) {
  return new L.TileLayer.WMSHeader(url, options, headers, abort)
}
