// @ts-nocheck
import React from 'react'
import { MainButton } from '@flint/core'
import { Typography } from '@material-ui/core'
import { useStyle } from './NewLayerSourceCardType.style'
import { LayerCardProps } from './NewLayerSourceCardType.interface'

export const NewLayerSourceCardType = ({
  title,
  text,
  icon,
  buttonText,
  onClick,
}: LayerCardProps) => {
  const classes = useStyle()

  const IconComponent = icon

  return (
    <div className={classes.layerCard}>
      <IconComponent className={classes.layerCardIcon} />
      <Typography className={classes.layerCardTitle}>{title}</Typography>
      <Typography className={classes.layerCardText}>{text}</Typography>
      <MainButton
        className={classes.layerCardButton}
        variant="contained"
        size="large"
        disableElevation
        fullWidth
        color="primary"
        onClick={onClick}
      >
        {buttonText}
      </MainButton>
    </div>
  )
}
