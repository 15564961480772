import { makeStyles, Theme } from '@material-ui/core'

const useStyle = makeStyles((theme: Theme) => ({
  detailsHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  detailsHeadeButton: {
    borderRadius: 10,
    fontWeight: 'bold',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
  detailsHeaderSelect: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  hide: {
    display: 'none',
  },
}))

export default useStyle
