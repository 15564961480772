import { cloneDeep } from 'lodash'
import { customAlphabet } from 'nanoid'

export const buildUiSchema = (
  jsonSchema: Record<string, any>,
  option: any = {}
) => {
  const uiSc: Record<string, any> = {}
  if (jsonSchema && Object.keys(jsonSchema).length) {
    Object.keys(jsonSchema.properties).forEach((key) => {
      const prop = jsonSchema.properties[key]
      const uisch = {
        'ui:size': 12,
        'ui:placeholder': prop.title,
        ...option,
      }
      uiSc[key] = uisch
    })
  }
  return uiSc
}

export const buildHeaderTitles = (jsonSchema: Record<string, any>) => {
  if (!jsonSchema || !Object.keys(jsonSchema).length) return []
  return Object.keys((jsonSchema || {})?.properties)
    .map((key) => ({
      text: jsonSchema.properties[key].title,
      key,
      isSummaryField: jsonSchema.properties[key].isSummaryField,
      isImageField: jsonSchema.properties[key].isImageField,
    }))
    .filter((head) => head.isSummaryField)
}

const isObject = (value: any) => value && value.constructor === Object

export const getMatchedData = (
  headers: string[],
  formData: Record<string, any>,
  callback: (key: string, value: any) => void
) => {
  Object.entries(formData).forEach(([key, value]) => {
    if (headers.includes(key)) {
      callback(key, value)
    } else if (isObject(value)) {
      getMatchedData(headers, value, callback)
    }
  })
}

export interface ISummarizedHeadProps {
  key: string
  title: string
  isImage: boolean
  format: string
  value?: any
}

export const getSummarizedProps = (
  jsonSchema: any,
  callback?: (head: ISummarizedHeadProps) => void
) => {
  Object.entries(jsonSchema || {}).forEach(([key, value]: any) => {
    if (isObject(value)) {
      if (value.isSummaryField) {
        const head = {
          format: value.format,
          key,
          title: value.title,
          isImage: value.isImageField,
        }

        if (callback) {
          callback(head)
        }
      } else {
        getSummarizedProps(value, callback)
      }
    } else if (Array.isArray(value)) {
      getSummarizedProps(value, callback)
    }
  })
}

export const buildSchema = (jsonSchema: any) => {
  // initialize schema
  const schema: any = {
    type: 'object',
    properties: {},
  }
  // initialize required array
  const required: Array<string> = []
  //  map data into schema
  jsonSchema.forEach((prop: any) => {
    // create new field with the name and append its object/defination

    const sch = {
      type: prop.type,
      title: prop.title,
      isSummaryField: prop.isSummaryField,
    }
    // make it attachment
    if (prop.type === 'attachment') {
      ;(sch as any).format = 'uri'
      sch.type = 'string'
    } else if (prop.type === 'date') {
      ;(sch as any).format = 'data'
      sch.type = 'string'
    }

    schema.properties[prop.name] = sch
    // if the field is required, append it to the required array
    if (prop.required) {
      required.push(prop.name)
    }
  })
  // if there are required fields, add theme to the schema
  if (required.length) {
    schema.required = required
  }

  return schema
}

export const arrPropsToObj = (jsonSchema: any) => {
  const schema = cloneDeep(jsonSchema)
  const properties: Array<{}> = (schema && schema.properties) || []
  const props = properties.reduce((prev: any, current: any) => {
    const { propKey } = current
    delete current.propKey
    prev[propKey] = current
    // transform attachment to url
    if (prev[propKey] && prev[propKey].hasOwnProperty('type')) {
      const type = prev[propKey].type
      if (type === 'attachment') {
        prev[propKey].type = 'array'
        prev[propKey].isImageField = true
        prev[propKey].items = {
          type: 'string',
          format: 'data-url',
        }
      } else if (type === 'date') {
        prev[propKey].type = 'string'
        prev[propKey].format = 'date'
      }
    }
    return prev
  }, {})
  schema.properties = props
  return schema
}

export const objPropsToArr = (jsonSchema: any) => {
  const clonedJson = cloneDeep(jsonSchema)
  const properties = Object.keys(clonedJson.properties).reduce(
    (arr: Array<any>, propKey: string) => {
      const object = cloneDeep(jsonSchema.properties[propKey])
      const isDataUrl = object.format === 'data-url'
      const isDate = object.format === 'date'
      object.propKey = propKey
      if (typeof object.isSummaryField === 'undefined') {
        object.isSummaryField = false
      }
      // transfer uri to attachment
      if (isDataUrl) {
        object.type = 'attachment'
      } else if (isDate) {
        object.type = 'date'
      }
      arr.push(object)
      return arr
    },
    []
  )
  clonedJson.properties = properties
  return clonedJson
}

const isAdvancedType = (type: any) =>
  Array.isArray(type) || type === 'array' || type === 'object'

export const isAdvancedSchema = (jsonSchema: any) => {
  const properties = jsonSchema.properties
  let isAdvanced = false
  // props is array
  if (Array.isArray(properties)) {
    for (const index in properties) {
      const prop = properties[index]
      if (isAdvancedType(prop.type)) {
        isAdvanced = true
        break
      }
    }
  } else {
    for (const propKey in properties) {
      if (isAdvancedType(properties[propKey].type)) {
        isAdvanced = true
        break
      }
    }
  }

  return isAdvanced
}

export const uniquePropName = (length: number = 20) =>
  customAlphabet('qwertyuiopasdfghjklzxcvbnm', length)().toLocaleLowerCase()

export const getArrayOfStrings = (data: Array<any> | any): string[] => {
  return Object.entries(data).reduce((prev, [, value]) => {
    if (typeof value === 'string') {
      prev.push(value)
    } else if (isObject(value) || Array.isArray(value)) {
      return [...prev, ...getArrayOfStrings(value)]
    }
    return prev
  }, [])
}
