import React, { useCallback, useEffect } from 'react'
import { SecondaryDrawer } from '@flint/core'
import {
  DetailsViewer,
  Layout,
  LayerCreationForm,
  EditLayer,
  RecordsFilter,
} from 'containers'
import { CreateLayerButton, GeoServerMap } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/reducer'

import { LayerFormAction } from 'store/layers/layer.reducer'

import { fetchLayers } from 'store/layers/layer.async'

import { useOrg } from 'hooks'
import { useLocation, useParams } from 'react-router-dom'
import { createLayerAction, setActiveRecord } from 'store/layers'
import layerService from 'services/layer.service'
import { DefaultOptions, EVENT_NAMES, PushStateArgs } from 'global'
import eventBus from 'utils/eventBus'

export const DashboardPage = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  // const navigate = useNavigate()
  const { layer } = useSelector((state: RootState) => state)
  const { activeOrg } = useOrg()

  const {
    layers,
    loadingLayers,
    selectedLayer,
    layerForm,
    loadingRecords,
    deletingLayer,
    deletingRecord,
    selectdRecord,
    highlightRecord,
  } = layer

  const isEditLayer = layerForm.action === LayerFormAction.UPDATE
  const params = useParams<{ layerId: any; recordId: any }>()

  // Get record id, and set page number.
  const setUpPageNumber = async (highlightRecordId?: any) => {
    const organizationId = activeOrg.id
    // get layer id from the URL
    const urlLayerId = params.layerId
    // get layer id stored in the local storage
    const localLayerId = localStorage.getItem('layerId')
    // Get select the layer from url, otherwise, get it from the local storage.
    const layerId = (urlLayerId || localLayerId) as any
    const recordId = params.recordId || highlightRecordId || null
    // fetch page number where the record is located
    if (recordId) {
      const pageNo = await layerService.queryPageNumber({
        layer: urlLayerId,
        record: recordId,
        limit: DefaultOptions.RECORD_LIMIT,
      })
      // if we found a page number, set the page number
      if (pageNo) {
        dispatch(createLayerAction('recordsPage', pageNo))
      }
    }

    dispatch(fetchLayers(organizationId, null, layerId, recordId))
  }

  // update state with highligted record id
  const setUpHighlightedRecord = (recordId: any) => {
    if (recordId) {
      dispatch(createLayerAction('highlightRecord', recordId))
    }
  }

  useEffect(() => {
    if (activeOrg && activeOrg.id) {
      const urlParams = new URLSearchParams(location.search)
      const recordId = urlParams.get('highlight')
      setUpPageNumber(recordId)
      setUpHighlightedRecord(recordId)
    }
  }, [params, activeOrg, location])

  /**
   * Set Active record when a record id found in the URL
   */
  useEffect(() => {
    // list all records
    const records = selectedLayer.records
    // check if there are records and record id in the url
    if (params.layerId && params.recordId && records) {
      // get record with a record ID
      const _record = records.find((r) => r.id === params.recordId)
      if (_record && !Object.keys(selectdRecord).length) {
        dispatch(setActiveRecord(_record))
      }
    }
  }, [params, selectedLayer.records])

  // listen on url change
  const onURLChange = useCallback(
    (state: PushStateArgs) => {
      // remove highlighted record if there is no highlight param in the URL
      if (!state.currentURL.searchParams.has('highlight') && highlightRecord) {
        dispatch(createLayerAction('highlightRecord', null))
      }
    },
    [highlightRecord]
  )

  useEffect(() => {
    eventBus.on(EVENT_NAMES.PUSH_STATE, onURLChange)
    return () => {
      eventBus.off(EVENT_NAMES.PUSH_STATE, onURLChange)
    }
  }, [onURLChange])

  return (
    <Layout
      enableDetailsView
      pageSubTitle="الخريطة التفاعلية"
      pageTitle="الرئيسية"
      toolbarAdornment={(<CreateLayerButton />) as any}
      secondaryDrawerEnabled
    >
      {!isEditLayer && <LayerCreationForm />}
      {isEditLayer && <EditLayer />}
      <GeoServerMap />
      <SecondaryDrawer header={{ title: 'بحث' }} hideButton>
        <RecordsFilter />
      </SecondaryDrawer>
      <DetailsViewer
        selectedLayer={selectedLayer}
        layers={layers}
        loading={
          loadingLayers || loadingRecords || deletingLayer || deletingRecord
        }
      />
    </Layout>
  )
}
