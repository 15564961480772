import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core'
import { Warning } from '@material-ui/icons'
import { useCallback, useEffect } from 'react'
import { ConfirmDialogProps } from './ConfirmDialog.interface'
import { useStyle } from './ConfirmDialog.style'

export const ConfirmDialog = ({
  isOpen,
  ignoreFn,
  submitFn,
}: ConfirmDialogProps) => {
  const classes = useStyle()
  const ignoreHandler = () => {
    if (ignoreFn) {
      ignoreFn()
    }
  }

  const onSubmit = useCallback(() => {
    if (submitFn) {
      submitFn()
    }
  }, [submitFn])

  const onKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      const keyCode = ev.keyCode

      if (keyCode === 13 && isOpen) {
        ev.preventDefault()
        onSubmit()
      }
    },
    [onSubmit, isOpen]
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  return (
    <Dialog
      open={isOpen}
      onEscapeKeyDown={ignoreHandler}
      maxWidth="sm"
      fullWidth
      style={{ textAlign: 'center', padding: 60 }}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>
        <Warning className={classes.warnIcon} />
        <Typography variant="h5" color="error">
          تحذير!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h5">لم تقم بحفظ التغيرات</Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          color="secondary"
          variant="contained"
          disableElevation
          onClick={ignoreHandler}
        >
          إلغاء
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          onClick={onSubmit}
        >
          حفظ و إستكمال
        </Button>
      </DialogActions>
    </Dialog>
  )
}
