import { makeStyles } from '@material-ui/styles'

export const useStyle = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  bg: {
    width: 20,
    height: 20,
    border: '1px solid #eee',
    marginRight: 10,
    borderRadius: 5,
    flexGrow: 0,
    flexShrink: 0,
  },
}))
