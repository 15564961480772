import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  clientName: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  logo: {
    height: 60,
    maxWidth: '100%',
  },
  imgWrapper: {
    borderBottom: 'solid 1px #ccc',
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
    width: '75%',
    marginBottom: theme.spacing(2 + 2.5),
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  swapButton: {
    position: 'absolute',
    right: '50%',
    transform: 'translateX(50%)',
    display: 'block',
    borderRadius: '50%',
    backgroundColor: '#eee',
    width: 40,
    height: 40,
    padding: 0,
    border: '1px solid #DFDFDF',
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
  orgs: {},
  orgItem: {
    border: '1px solid #E8E8E8',
    borderRadius: 10,
    padding: 10,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    cursor: 'pointer',
  },
  orgLogo: {
    maxWidth: '100%',
  },
  dialogPaper: {
    padding: 10,
  },
  activeOrg: {
    borderColor: theme.palette.primary.main,
  },
  orgName: {
    fontSize: 15,
    fontWeight: 'bold',
  },
}))
