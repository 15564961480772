import { Grid } from '@material-ui/core'
import { LayerImort, LayerNew } from 'icons'
import { useDispatch, useSelector } from 'react-redux'
import { createLayerFormAction } from 'store/layers/layer.actions'
import { RootState } from 'store/reducer'
import { NewLayerSourceCardType } from './NewLayerSourceCardType'

export const NewLayerSource = () => {
  const dispatch = useDispatch()
  const { layerForm } = useSelector((state: RootState) => state.layer)
  const onClick = (source: 'regular' | 'dataset') => {
    dispatch(
      createLayerFormAction({ ...layerForm, step: layerForm.step + 1, source })
    )
  }

  return (
    <Grid container spacing={5} justify="center">
      <Grid item>
        <NewLayerSourceCardType
          title="طبقة جديدة"
          text="إنشاء طبقة جديدة فارغة, ويمكن تعريف الحقول المطلوبة"
          icon={LayerNew}
          onClick={() => onClick('regular')}
          buttonText="إنشاء"
        />
      </Grid>
      <Grid item>
        <NewLayerSourceCardType
          title="إستيراد طبقة"
          onClick={() => onClick('dataset')}
          text="إنشاء طبقة عن طريق استيراد البيانات من ملف اكسل أو csv"
          icon={LayerImort}
          buttonText="إستيراد"
        />
      </Grid>
    </Grid>
  )
}
