import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
    alignItems: 'center',
  },
  modalHeaderTitle: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  stepsCounter: {
    display: 'flex',
    alignItems: 'center',
  },
  dotsWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: '#D2D2D2',
    margin: theme.spacing(0, 0.3),
    cursor: 'pointer',
  },
  acitve: {
    backgroundColor: theme.palette.primary.main,
  },
  hide: {
    display: 'none',
  },
}))
