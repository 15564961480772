import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    backgroundColor: '#f7f7f7',
    overflow: 'hidden',
  },
  hide: {
    display: 'none',
  },
  container: {
    width: '100%',
    maxWidth: 1200,
    overflow: 'hidden',
    backgroundColor: '#fff',
    borderRadius: theme.spacing(7.5),
  },
  headerContainer: {
    padding: theme.spacing(5, 0),
  },
  platformDescription: {
    textAlign: 'center',
    padding: theme.spacing(3, 6),
    borderRight: '1px solid #ededed',
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
    },
  },
  platformContent: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderBottom: '1px solid #ededed',
    },
  },
  headerBannerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 6),
  },
  headerBanner: {
    maxHeight: 450,
  },
  logo: {
    height: 100,
    marginBottom: theme.spacing(4),
  },
  headerTitle: {
    fontSize: 40,
    marginTop: 0,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2.5),
  },
  headerDescription: {
    fontSize: 14,
    lineHeight: 2.21,
    color: '#5b5b5b',
    textAlign: 'justify',
  },
  footerContainer: {
    color: '#fff',
    backgroundColor: '#3b3b3b',
    padding: theme.spacing(5, 0),
  },
  loginContainer: {
    textAlign: 'center',
    padding: theme.spacing(0, 6),
    borderRight: '1px solid #606060',
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
      paddingBottom: theme.spacing(3),
    },
  },
  loginContent: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(3),
      borderBottom: '1px solid #606060',
    },
  },
  loginTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  actionsContainer: {
    width: '100%',
    display: 'flex',
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(2.5),
  },
  loginButton: {
    fontSize: 18,
    width: '100%',
    color: '#b72d40',
    borderRadius: 10,
    fontWeight: 'bold',
    backgroundColor: '#fff',
    padding: theme.spacing(1.5),
    '&:hover': {
      backgroundColor: '#d4cfcf',
    },
  },
  newRegisterButton: {
    fontSize: 18,
    width: '100%',
    color: '#fff',
    borderRadius: 10,
    fontWeight: 'bold',
    backgroundColor: '#b72d40',
    padding: theme.spacing(1.5),
    marginLeft: theme.spacing(3),
    '&:hover': {
      backgroundColor: '#882230',
    },
  },
  termsOfUse: {
    fontSize: 15,
    color: '#fff',
    textDecoration: 'underline',
  },
  appLinkContainer: {
    textAlign: 'center',
    padding: theme.spacing(0, 6),
    borderRight: '1px solid #606060',
  },
  downloadTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  storesContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
  },
  storeLink: {
    height: '100%',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
  },
  googleStoreLogo: {
    width: 170,
  },
  appleStoreLogo: {
    width: 150,
  },
  copyright: {
    fontSize: 15,
    color: '#fff',
  },
}))
