import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { ColorsPalette } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { createLayerFormAction } from 'store/layers/layer.actions'
import { RootState } from 'store/reducer'
import { LayerFormAction } from 'store/layers/layer.reducer'
import { LayerDetailsForm } from './LayerDetailsForm'

export const NewLayerFrom = () => {
  const { submitForm } = useFormikContext()
  const dispatch = useDispatch()
  const { layerForm } = useSelector((state: RootState) => state.layer)
  const isEdit = layerForm.action === LayerFormAction.UPDATE

  const moveBack = () => {
    dispatch(createLayerFormAction({ isActive: !isEdit, step: 0 }))
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <LayerDetailsForm />
        </Grid>
        <Grid item sm={6}>
          <ColorsPalette name="geometryColor" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            size="large"
            disableElevation
            onClick={submitForm}
          >
            {isEdit ? 'حفظ' : 'التالى'}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            style={{ backgroundColor: '#616060', color: '#fff' }}
            variant="contained"
            fullWidth
            size="large"
            disableElevation
            onClick={moveBack}
          >
            {isEdit ? 'إلفاء' : 'رجوع'}
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
