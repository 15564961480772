import { LayerPermissions } from 'global'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/reducer'

type UseLayerPermissionKey = keyof typeof LayerPermissions

export const useLayer = () => {
  const { selectedLayer } = useSelector((state: RootState) => state.layer)
  const permissions = useMemo(() => {
    return selectedLayer.userPermissions || []
  }, [selectedLayer])
  const userCan = useCallback(
    (key: UseLayerPermissionKey) => {
      return permissions.includes(LayerPermissions[key])
    },
    [permissions]
  )
  return {
    userCan,
  }
}
