import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F6F6F6',
    borderRadius: 40,
    fontWeight: 'bold',
    padding: theme.spacing(1, 2),
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    color: 'white',
    marginRight: theme.spacing(1),
  },
}))
