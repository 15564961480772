import { Checkbox, FormControlLabel } from '@material-ui/core'
import { useField } from 'formik'
import { GCheckboxProps } from './GCheckbox.interface'

export const GCheckbox = ({
  name,
  value,
  label,
  formControlLabelProps,
  ...props
}: GCheckboxProps) => {
  const [field] = useField({ name, value, type: 'checkbox' })
  return (
    <FormControlLabel
      {...formControlLabelProps}
      control={<Checkbox {...field} {...props} />}
      label={label}
    />
  )
}
