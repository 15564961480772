import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  formRow: {
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  formGroup: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  actionIcon: {
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    margin: theme.spacing(0, 1),
    width: 30,
    height: 30,
    fontWeight: 'bold',
    fontSize: 20,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:disabled': {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  checkboxLabel: {
    fontSize: 12,
  },
}))
