import createResource, { Resource } from './createResource'

const cache = new Map<string, any>()

const loadImage = (source: string): Resource<string> => {
  let resource = cache.get(source)
  if (resource) return resource

  resource = createResource<string>(
    () =>
      new Promise((reslove) => {
        const img = new window.Image()
        img.src = source
        img.addEventListener('load', () => reslove(source))
      })
  )
  cache.set(source, resource)
  return resource
}

export default loadImage
