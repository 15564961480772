import { makeStyles, withStyles, InputBase } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: ['Almarai, sans-serif'].join(','),
  },
  inputRoot: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  label: {
    fontSize: 11,
    fontWeight: 'bold',
    color: 'rgb(22, 21, 26)',
    marginTop: '-22px',
  },
}))
export const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '16px 12px',
    lineHeight: '19px',
    color: 'rgb(22, 21, 26)',
    fontFamily: ['Almarai'].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: theme.palette.primary.main,
      color: 'rgb(22, 21, 26) !important',
    },
  },
}))(InputBase)
