import { LatLngBounds } from 'leaflet'

import proj4 from 'proj4'
import { reproject } from 'reproject'

export const stringifyBounds = (bounds: any) => {
  const polygon = `POLYGON (( ${bounds.getNorthEast().lng}   ${
    bounds.getNorthEast().lat
  },${bounds.getNorthEast().lng}   ${bounds.getNorthEast().lat},${
    bounds.getSouthWest().lng
  } ${bounds.getNorthEast().lat},${bounds.getSouthWest().lng}${
    bounds.getNorthEast().lat
  },${bounds.getNorthEast().lng}   ${bounds.getNorthEast().lat} ))`
  return polygon
}

export const genericBoundsToNwSe = (bounds: any) => {
  const SouthEast: any = [bounds.getNorthEast().lng, bounds.getSouthWest().lat]
  const NorthWest: any = [bounds.getSouthWest().lng, bounds.getNorthEast().lat]
  return {
    nw: {
      lat: NorthWest[1],
      lng: NorthWest[0],
    },
    se: {
      lat: SouthEast[1],
      lng: SouthEast[0],
    },
  }
}

export const simplifyCoord = (coords: any) => {
  if (Array.isArray(coords[0])) {
    if (Array.isArray(coords[0][0])) {
      return `${coords[0][0].join(',')}....`
    }
    return `${coords[0].join(',')}....`
  }
  return coords.join(',')
}

export const boundsToString = (bounds: LatLngBounds): string => {
  return `[${bounds.toBBoxString()}]`
}

// allow proj4 to identify epsg5387 and epsg4326 projection defs
proj4.defs(
  'EPSG:3857',
  '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext +no_defs'
)
proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs')

export const epsg4326 = 'EPSG:4326'
export const epsg5387 = 'EPSG:3857'

export const isEPSG3857 = (point5387: [number, number]) => {
  // try to parse a point geometry coordinates and check if its projection is epsg5387
  const epsg5387RangeX = [1200000, 1300000] // Expected X coordinate range
  const epsg5387RangeY = [6600000, 6800000] // Expected Y coordinate range
  return (
    point5387[0] >= epsg5387RangeX[0] &&
    point5387[0] <= epsg5387RangeX[1] &&
    point5387[1] >= epsg5387RangeY[0] &&
    point5387[1] <= epsg5387RangeY[1]
  )
}

export const reProjectGeoJSONTo4326 = (geojson3857) => {
  return reproject(geojson3857, 'EPSG:3857', 'EPSG:4326')
}

export const parseGeoServerCRS = ({ code, properties }: any) => {
  if (code === epsg5387 || properties?.name === 'urn:ogc:def:crs:EPSG::3857') {
    return epsg5387
  }
}
