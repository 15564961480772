// @ts-nocheck
import React, { FunctionComponent, useMemo, useState } from 'react'
import { Typography, Grid, Box } from '@material-ui/core'

import { LoadingProgress, MainButton } from '@flint/core'
import { FormMode, IRecord } from 'global'
import { useDispatch, useSelector } from 'react-redux'
import { deleteRecord } from 'store/layers/layer.async'
import { RootState } from 'store/reducer'
import {
  changeRecordsView,
  setActiveLayer,
  setActiveRecord,
} from 'store/layers'
import clsx from 'clsx'

import { useLayer } from 'hooks'
import { Modal } from 'components'
import { PopupColumn } from './PopupColumn'
import { useStyle } from './RecordPopup.style'
import { IProps } from './RecordPopup.interface'

export const RecordPopup: FunctionComponent<IProps> = ({
  record,
  layer,
}: IProps) => {
  const classes = useStyle()
  const dispatch = useDispatch()
  const { deletingRecord } = useSelector((state: RootState) => state.layer)
  const { userCan } = useLayer()
  const [openModal, setOpenModal] = useState(false)
  const layerTitles = useMemo(() => {
    const titles: Record<string, any> = {}
    const props =
      (layer && layer.jsonSchema && layer.jsonSchema.properties) || {}
    Object.keys(props).forEach((propKey) => {
      titles[propKey] = {
        title: props[propKey].title,
        isSummaryField: props[propKey].isSummaryField,
        isImageField: props[propKey].isImageField,
      }
    })
    return titles
  }, [layer])

  const data = useMemo(() => {
    const recordCols = [] as any[]
    let recordData = (record.data || (record as any).properties) as any
    recordData = (recordData.data && JSON.parse(recordData.data)) || recordData

    Object.keys(recordData).forEach((prop: any) => {
      const property = layerTitles[prop]
      if (property && property.isSummaryField) {
        const rec = {
          title: property.title,
          value: recordData[prop],
          isImageField: property.isImageField,
        }
        recordCols.push(rec)
      }
    })
    return recordCols
  }, [layerTitles, record])

  const handleModalClose = () => {
    setOpenModal(false)
  }
  const onDelete = () => {
    dispatch(deleteRecord(record.id, layer.geometryType, layer.id))
    setOpenModal(false)
  }
  const onEdit = (_record: IRecord) => {
    dispatch(
      setActiveLayer(layer, () => {
        dispatch(setActiveRecord(_record))
        dispatch(changeRecordsView(FormMode.EDIT))
      })
    )
  }

  return (
    <div className={classes.root}>
      <LoadingProgress loading={deletingRecord} />
      {/* Content */}
      <Box display="flex" alignItems="center" marginBottom={2}>
        <div
          style={{
            margin: 0,
            fontSize: 12,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              border: '1px solid',
              borderColor: layer.geometryColor,
              marginLeft: 10,
              borderRadius: 10,
              height: 30,
              width: 30,
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: layer.geometryColor,
                opacity: 0.2,
              }}
            />
          </div>
        </div>
        <Typography style={{ margin: 0, fontWeight: 600 }}>
          {/* {((record.createdAt || record.created_at) as unknown as string).split('T')[0]} */}
        </Typography>
        <strong>{record.layer.title}</strong>
      </Box>
      <Grid container alignItems="center" wrap="wrap">
        {data.map((item: any, index) => (
          <PopupColumn
            title={item.title}
            body={item.value}
            key={index}
            isImageField={item.isImageField}
          />
        ))}
      </Grid>
      {/* Footer */}
      <Grid
        container
        spacing={2}
        style={{ marginTop: 10 }}
        className={clsx({ [classes.hide]: layer.isGtLayer })}
      >
        {userCan('EDIT_RECORD') && (
          <Grid item sm={6}>
            <MainButton
              color="primary"
              variant="contained"
              fullWidth
              disableElevation
              style={{ borderRadius: 20 }}
              onClick={() => onEdit(record)}
              // disabled={deleting && recordDeleted === record.id}
            >
              تعديل
            </MainButton>
          </Grid>
        )}
        {userCan('DELETE_RECORD') && (
          <Grid item sm={6}>
            <MainButton
              color="secondary"
              style={{ borderRadius: 20 }}
              variant="contained"
              disableElevation
              fullWidth
              // disabled={deleting && recordDeleted === record.id}
              onClick={() => setOpenModal(true)}
            >
              حذف
            </MainButton>
          </Grid>
        )}
      </Grid>
      <Modal
        open={openModal}
        message="هل تريد حذف هذا السجل ؟"
        onClickCloseModal={handleModalClose}
        confirmButtonText="نعم"
        onClickConfirmButton={onDelete}
      />
    </div>
  )
}
