class EventBus {
  // store all events
  private events: Record<string, any[]> = {}

  // listen on an event
  public on<T = any>(eventName: string, callback: T): EventBus {
    // validate callback is passed
    if (!callback) {
      throw new Error('Please pass your callback')
    }

    if (!this.events.hasOwnProperty(eventName)) {
      this.events[eventName] = []
    }

    this.events[eventName].push(callback)
    return this
  }

  public emit<T = any>(eventName: string, ...args: T[]) {
    // check if event is not exists
    if (!this.events[eventName]) {
      throw new Error(`Event with name ${eventName} does in exist`)
    }

    // fiire the event
    this.events[eventName].forEach((callback: any) => {
      callback.apply(callback, args)
    })
  }

  public off(eventName: string, callback?: any) {
    // remove all listners if there is no a callback passed
    if (!callback) {
      delete this.events[eventName]
      return true
    }

    // delete for a specific callback
    const callbackIndex = this.events[eventName].findIndex(
      (c) => c === callback
    )

    if (callbackIndex !== -1) {
      // delete the callback from the array
      this.events[eventName].splice(callbackIndex, 1)
      return true
    }
    // return false if there is no effected callback
    return false
  }
}

export default new EventBus()
