import { makeStyles } from '@material-ui/styles'

import { Theme } from '@material-ui/core'

export const useStyle = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  warnIcon: {
    fontSize: 50,
    color: theme.palette.warning.main,
  },
}))
