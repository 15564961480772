import React, { FunctionComponent, useState } from 'react'
import clsx from 'clsx'
import { Typography, Grid, Dialog, Paper } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { isDataURL } from 'utils/common'
import { ImageSuspense } from 'components/GeoTable/ImageSuspense'
import useStyle from './PopupColumn.style'
import { IProps } from './PopupColumn.interface'

export const PopupColumn: FunctionComponent<IProps> = ({
  title,
  body,
  hideDivider,
  bodyColor = '#000',
  isImageField = false,
}: IProps) => {
  const classes = useStyle()
  const [largeImageView, setLargeImageView] = useState<any>()

  if (Array.isArray(body)) {
    return (
      <Grid
        item
        sm={12}
        className={clsx(classes.root, {
          [classes.border]: !hideDivider,
        })}
      >
        <Dialog
          maxWidth="lg"
          open={!!largeImageView}
          onClose={() => setLargeImageView(null)}
        >
          <Paper style={{ padding: 10 }}>
            <img
              src={largeImageView}
              alt="any"
              style={{ margin: 'auto', maxWidth: '100%' }}
            />
          </Paper>
        </Dialog>
        <Typography className={classes.title}>{title}</Typography>
        <List>
          {body.map((d) => (
            <ListItem disableGutters dense>
              {isDataURL(d) ? (
                <ImageSuspense
                  onImageClicked={() => setLargeImageView(d)}
                  src={d}
                />
              ) : (
                <Typography
                  className={classes.body}
                  style={{ color: bodyColor }}
                >
                  {d}
                </Typography>
              )}
            </ListItem>
          ))}
        </List>
      </Grid>
    )
  }

  return (
    <Grid
      item
      className={clsx(classes.root, {
        [classes.border]: !hideDivider,
      })}
    >
      <Dialog
        maxWidth="lg"
        open={!!largeImageView}
        onClose={() => setLargeImageView(null)}
      >
        <Paper style={{ padding: 10 }}>
          <img
            src={largeImageView}
            alt="any"
            style={{ margin: 'auto', maxWidth: '100%' }}
          />
        </Paper>
      </Dialog>
      <Typography className={classes.title}>{title}</Typography>
      {isImageField ? (
        <img
          onClick={(event: any) => {
            event.stopPropagation()
            setLargeImageView(Array.isArray(body) ? body[0] : body)
          }}
          src={Array.isArray(body) ? body[0] : body}
          alt={title}
          width={100}
          style={{ padding: 2, background: '#747474', cursor: 'pointer' }}
        />
      ) : (
        <Typography className={classes.body} style={{ color: bodyColor }}>
          {body}
        </Typography>
      )}
    </Grid>
  )
}
