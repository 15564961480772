import { Suspense } from 'react'
import { Skeleton } from '@material-ui/lab'
import SuspenseImage from 'utils/resources/SuspenseImage'

export interface IImageSuspenseProps {
  src: string
  onImageClicked: (url: string) => void
}

export const ImageSuspense = ({ src, onImageClicked }: IImageSuspenseProps) => (
  <Suspense fallback={<Skeleton variant="rect" width={101} height={102} />}>
    <SuspenseImage
      onClick={(event: any) => {
        event.stopPropagation()
        if (onImageClicked) {
          onImageClicked(src)
        }
      }}
      src={src}
      width={100}
      style={{ padding: 2, background: '#747474' }}
    />
  </Suspense>
)
