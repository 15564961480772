import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/reducer'
import { cloneDeep } from 'lodash'

export const useJsonSchemaMapper = () => {
  const { layerForm } = useSelector((state: RootState) => state.layer)

  const isWktCol = useCallback(
    (key: string) => {
      if (layerForm.geoType === 'wkt') {
        if (key === layerForm.wktColumn) return true
      }
      return false
    },
    [layerForm.geoType, layerForm.wktColumn]
  )

  const isLatlngCol = useCallback(
    (key: string) => {
      const { latitude, longitude } = layerForm.lonLatColumns
      if (latitude === key || longitude === key) {
        return true
      }
      return false
    },
    [layerForm.lonLatColumns]
  )
  const isCoordCol = useCallback(
    (key: string) => {
      return isWktCol(key) || isLatlngCol(key)
    },
    [isWktCol, isLatlngCol]
  )
  const isCoordKey = useCallback(
    (propKey: string) =>
      layerForm.isFileUploaded && !layerForm.isGeoJson && isCoordCol(propKey),
    [isCoordCol, layerForm.isFileUploaded, layerForm.isGeoJson]
  )

  const mapJsonSchemaObjToArr = useCallback(
    (jsonSchema: any) => {
      const properties = Object.keys(jsonSchema.properties).reduce(
        (arr: Array<any>, propKey: string) => {
          if (isCoordKey(propKey)) {
            return arr
          }
          const object = cloneDeep(jsonSchema.properties[propKey])
          object.propKey = propKey
          object.isSummaryField = false
          arr.push(object)
          return arr
        },
        []
      )
      const updatedJsonSchema = cloneDeep(jsonSchema)
      updatedJsonSchema.properties = properties
      // remove key form required
      const propKeys = properties.map((o) => o.propKey)
      const requiredKeys: string[] = updatedJsonSchema.required
      updatedJsonSchema.required = requiredKeys.filter((propKey: string) =>
        propKeys.includes(propKey)
      )
      return updatedJsonSchema
    },
    [isCoordKey]
  )

  return {
    isLatlngCol,
    isWktCol,
    isCoordCol,
    isCoordKey,
    mapJsonSchemaObjToArr,
  }
}
