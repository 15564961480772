import React, { useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { CreateLayerRequest } from 'global'
import { FormRowActionButtons } from './FormRowActionButtons'
import { FormRowCheckboxes } from './FormRowCheckboxes'
import { FormRowFields } from './FormRowFields'
import { FormRowProps } from './FormRow.interface'
import { useStyle } from './FormRow.style'

export const FormRow = ({
  rowIndex,
  formLength,
  onAppend,
  onRemove,
  disableInteraction = false,
}: FormRowProps) => {
  const classes = useStyle()
  const disableTitle = rowIndex > 0
  const enableRemove = formLength > 1
  const enableAdd = formLength === rowIndex + 1
  const { values } = useFormikContext<CreateLayerRequest>()
  const disableSummary = useMemo(() => {
    return (
      values.jsonSchema.properties.filter((field) => field.isSummaryField)
        .length > 5 && !values.jsonSchema.properties[rowIndex].isSummaryField
    )
  }, [values, rowIndex])

  return (
    <Grid container className={classes.formRow}>
      <Grid item xs={11}>
        <Grid container spacing={1} alignItems="center">
          <FormRowFields
            rowIndex={rowIndex}
            disableInteraction={disableInteraction}
            disableTitle={disableTitle}
          />
          <FormRowCheckboxes
            rowIndex={rowIndex}
            disableSummary={disableSummary}
            disableTitle={disableTitle}
          />
        </Grid>
      </Grid>
      {!disableInteraction && (
        <FormRowActionButtons
          rowIndex={rowIndex}
          enableAdd={enableAdd}
          enableRemove={enableRemove}
          onRemove={onRemove}
          onAppend={onAppend}
        />
      )}
    </Grid>
  )
}
