// @ts-nocheck

import { useMemo } from 'react'
import clsx from 'clsx'
import { getMatchedData, ISummarizedHeadProps } from 'utils/jsonSchema'

import { cloneDeep } from 'lodash'
import { StyledTableRow, useStyle } from './GeoTable.style'
import { GeoTableBodyCell } from './GeoTableBodyCell'
import { GeoTableCustomBodyCell } from './GeoTableCustomBodyCell'
import { GeoTableRowProps } from './GeoTable.interface'

export const GeoTableRow = ({
  row,
  headers,
  onImageClicked,
  onRowClick,
  highlight,
}: GeoTableRowProps) => {
  const classes = useStyle()
  const data = useMemo((): ISummarizedHeadProps[] => {
    const __data = cloneDeep(headers)
    const __headerKeys = headers.map((h) => h.key)
    getMatchedData(__headerKeys, row.data, (key, value) => {
      const index = __data.findIndex((h) => h.key === key)
      __data[index].value = value
    })
    return __data
  }, [row, headers])

  return (
    <StyledTableRow
      style={{ cursor: 'pointer' }}
      className={clsx({ [classes.highlight]: highlight })}
    >
      {data.map((d) => (
        <GeoTableCustomBodyCell key={d.key} onClick={() => onRowClick(row)}>
          <GeoTableBodyCell {...d} onImageClicked={onImageClicked} />
        </GeoTableCustomBodyCell>
      ))}
    </StyledTableRow>
  )
}
