import { Popup, FeatureGroup } from 'react-leaflet'

import { EditControl } from 'react-leaflet-draw'

import { useDispatch } from 'react-redux'
import {
  createSelectPointAction,
  setGeoJson,
  setLfLayer,
  setOpenedPopup,
} from 'store/layers/layer.actions'
import { useRef } from 'react'
import { AddToLayerMenu } from '../AddToLayerMenu'

const { stringify } = require('wkt')

export const DrawShapes = () => {
  const dispatch = useDispatch()
  const featureRef = useRef<any>(null)

  const deleteOld = () => {
    const feat = featureRef.current

    const lyrs = featureRef.current._layers
    // if the number of layers is bigger than 1 then delete the first
    if (Object.keys(lyrs).length > 1) {
      Object.keys(lyrs).forEach((layerid, index) => {
        if (index > 0) return
        const layer = lyrs[layerid]
        feat.removeLayer(layer)
      })

      // open popup
      // const cLyrid = Object.keys(lyrs)[0]
      //  const lyr = lyrs[cLyrid]
    }
  }

  const onCreated = (event: any) => {
    deleteOld()
    const geojson = event.layer.toGeoJSON()
    const wkt = stringify(geojson)
    dispatch(setGeoJson(geojson))
    dispatch(createSelectPointAction(wkt))
    dispatch(setLfLayer(event.layer))

    const layer = event.layer
    layer.on('popupopen', ({ popup }) => {
      dispatch(setOpenedPopup(popup))
    })
  }

  return (
    <FeatureGroup ref={featureRef}>
      {/* @ts-ignore */}
      <EditControl
        position="bottomright"
        onCreated={onCreated}
        draw={{
          rectangle: false,
          marker: false,
          circle: false,
          circlemarker: {
            radius: 7,
            fillOpacity: 1,
            fillColor: '#b72d40',
            stroke: false,
          },
        }}
      />

      <Popup>
        <AddToLayerMenu />
      </Popup>
    </FeatureGroup>
  )
}
