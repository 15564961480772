import { Typography } from '@material-ui/core'
import { NoOrg } from 'icons'
import { Layout } from 'containers'
import { useStyle } from './NoOrganization.style'

export function NoOrganization() {
  const classes = useStyle()
  return (
    <Layout pageSubTitle="الخريطة التفاعلية" pageTitle="الرئيسية">
      <div className={classes.root}>
        <NoOrg />
        <Typography className={classes.title}>
          حسابكم غير مدرج تحت أي منشأة, إذا كنت مخول لإنشاء حساب لمنشأتك الرجاء
          التواصل مع فريق المبيعات:
        </Typography>
        <Typography className={classes.mailText}>contact@gt.com.sa</Typography>
        <Typography className={classes.textBody}>
          إذا كنت موظف تابع لمنشأة مسجلة في المنصة, يرجى التواصل مع مدير حسابكم
          لدعوتك
        </Typography>
      </div>
    </Layout>
  )
}
