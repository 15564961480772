import ArCommon from './langs/ar/common.json'
import ArValidation from './langs/ar/validation.json'
import enCommon from './langs/en/common.json'
import enValidation from './langs/en/validation.json'

export const resources = {
  en: {
    translation: enCommon,
    validation: enValidation,
  },
  ar: {
    translation: ArCommon,
    validation: ArValidation,
  },
}
