import { string, object, array, addMethod, bool } from 'yup'

// create a new validation rule that check uniquens accros array
addMethod(array, 'unique', function (message, mapper = (a: any) => a) {
  return this.test('unique', message, function (list: any) {
    return list.length === new Set(list.map(mapper)).size
  })
})

export const validationSchemas = [
  object().shape({
    name: string()
      .min(3, 'يجب ان يكون ٣ حروف أو أكثر')
      .max(20, 'يجب ان يكون الاسم ٢٠ حرف بحد أقصى')
      .matches(/^(?=[a-zA-Z0-9._-])(?!.*[_.]{2})[^_.].*[^_.]$/, {
        message:
          'يجب ان يحتوي على حروف انجليزية و ارقام فقط ولا يحتوي على مسافات',
      })
      .required('إسم الحقل مطلوب'),
    title: string().required('عنوان الحقل مطلوب'),
    geometryType: string().oneOf(['POINT', 'POLYGON', 'LINESTRING']),
    description: string(),
    geometryColor: string().required(),
  }),
  object().shape({
    jsonSchema: object().shape({
      properties: array().of(
        object().shape({
          propKey: string()
            .matches(/^(?=[a-zA-Z0-9._-]{3,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/, {
              message:
                'يجب ان يحتوي على حروف انجليزية و ارقام فقط ولا يحتوي على مسافات',
            })
            .required('إسم الحقل مطلوب'),
          title: string().required('عنوان الحقل مطلوب'),
          type: string()
            .oneOf(['string', 'number', 'attachment', 'date'])
            .default('string'),
          isSummaryField: bool().default(false),
        })
      ),
      required: array(string()),
    }),
  }),
]
