import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  border: {
    borderBottom: '1px solid #f5f5f5',
  },
  title: {
    fontSize: 12,
    color: '#7c7c7c',
    fontFamily: 'Almarai, sans-serif',
    margin: '0!important',
    textAlign: 'left',
  },
  body: {
    fontSize: 13,
    fontWeight: 900,
    color: '#000',
    fontFamily: 'Almarai, sans-serif',
    wordBreak: 'break-word',
    margin: '0!important',
    textAlign: 'left',
  },
}))

export default useStyle
