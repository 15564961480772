import React from 'react'
import { Button } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { Field, useField, useFormikContext } from 'formik'
import clsx from 'clsx'
import { isBrightenColor } from 'utils/colors'
import { ColorsPaletteProps } from './ColorsPalette.interface'
import { colors, useStyle } from './ColorsPalette.style'

export function ColorsPalette({ name }: ColorsPaletteProps) {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(name)
  const { value } = field

  const changeColor = (color: string) => {
    setFieldValue(name, color)
  }
  const classes = useStyle()
  return (
    <div>
      <ul className={classes.colorsWrapper}>
        {colors.map((color, index) => (
          <li
            className={clsx(classes.colorItem, {
              [classes.activeColor]: field.value === color,
            })}
            key={index}
            onClick={() => changeColor(color)}
            style={{ backgroundColor: color }}
          >
            <CheckIcon
              fontSize="large"
              className={clsx({ [classes.hide]: color !== field.value })}
              style={{ color: isBrightenColor(color) ? 'black' : 'white' }}
            />
          </li>
        ))}
      </ul>
      <Button
        disableElevation
        variant="contained"
        color="primary"
        fullWidth
        className={clsx(classes.ontherColorButton)}
        size="large"
        style={{
          backgroundColor: value,
          color: isBrightenColor(value) ? 'black' : 'white',
        }}
      >
        <Field type="color" {...field} className={classes.colorInput} />
        إختيار لون اخر
      </Button>
    </div>
  )
}
