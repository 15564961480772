import { makeStyles } from '@material-ui/core/styles'

export const useStyle = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  layersIcon: {},
  layerText: {
    fontWeight: 'bold',
  },
  textWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  layersButton: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  buttonsWrapper: {},
  editLayerButton: {
    borderRadius: 20,
    color: theme.palette.common.black,
    fontWeight: 'bold',
    borderWidth: 1.3,
    '&:hover': {
      borderWidth: 1.3,
    },
  },
  deleteLayerButton: {
    borderRadius: 20,
    fontWeight: 'bold',
    borderWidth: 1.3,
    '&:hover': {
      borderWidth: 1.3,
    },
  },
  searchButton: {
    borderRadius: 10,
    backgroundColor: '#eeeeee',
    marginLeft: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
}))
