import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    margin: theme.spacing(2, 0),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
}))
