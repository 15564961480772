import React from 'react'

export function StyleInjector({ children, classes }: any) {
  const StyledChildren = () =>
    React.Children.map(children, (child) =>
      React.cloneElement(child, {
        className: `${child.props.className} ${classes}`,
      })
    )

  return <StyledChildren />
}
