import React, { useState, useEffect, useRef } from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { AddBasicIcon, XlIcon } from 'icons'
import { useDispatch } from 'react-redux'
import { createLayerFormAction } from 'store/layers'
import { useStyle } from './UploadDataset.style'

export const UploadDataset = () => {
  const { submitForm, setFieldValue } = useFormikContext()
  const rootRef = useRef<any>()
  const inputRef = useRef<any>()
  const dispatch = useDispatch()
  const [fileInfo, setFileInfo] = useState<any>({})
  const [datasetFile, setFile] = useState<any>()
  const [fileConfiremd, setConfifirm] = useState(false)

  const moveBack = () => {
    dispatch(createLayerFormAction({ isActive: true, step: 1 }))
  }

  useEffect(() => {}, [])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = ((event as any).dataTransfer ||
      event.target.files) as FileList
    const file = files[0]

    if (!file) {
      return
    }
    setFieldValue('originalDataset', file)
    setFile(file)
    setFileInfo({ name: file.name })
    setConfifirm(false)
  }

  const changeFile = () => {
    const input = inputRef.current as HTMLInputElement
    input.click()
  }

  const classes = useStyle()
  return (
    <div className={classes.root}>
      <div className={classes.uploadWrapper} ref={rootRef.current}>
        {datasetFile && <XlIcon width={100} height={100} />}
        {!datasetFile && <AddBasicIcon width={40} height={40} />}
        <Typography>{fileInfo.name || 'إرفاق ملف'}</Typography>
        <input
          type="file"
          ref={inputRef}
          style={{ display: fileConfiremd ? 'none' : 'block' }}
          className={classes.input}
          onChange={onChange}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.geojson,application/JSON"
        />
        <div
          style={{
            display: datasetFile ? 'block' : 'none',
            marginTop: 20,
          }}
        >
          {!fileConfiremd && (
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={() => setConfifirm(true)}
              style={{ marginLeft: 10 }}
            >
              تأكيد صحة الملف
            </Button>
          )}
          <Button
            color="secondary"
            variant="contained"
            disableElevation
            onClick={changeFile}
          >
            تغير الملف
          </Button>
        </div>
      </div>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            size="large"
            disableElevation
            onClick={submitForm}
            disabled={!fileConfiremd}
          >
            حفظ
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            color="secondary"
            variant="contained"
            fullWidth
            size="large"
            disableElevation
            onClick={moveBack}
          >
            رجوع
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
