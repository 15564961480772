import { CircularProgress, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { LayerFormModalLoaderProps } from './LayerFormModal.interface'
import { useStyle } from './LayerFormModal.style'

export const LayerFormModalLoader = ({
  loading,
}: LayerFormModalLoaderProps) => {
  const classes = useStyle()

  return (
    <div className={clsx(classes.loaderRoot, { [classes.hide]: !loading })}>
      <CircularProgress />
      <Typography>برجاء الإنتظار</Typography>
    </div>
  )
}
