/* global NodeJS */
// @ts-nocheck
import { LoadingProgress } from '@flint/core'
import { Box, Button, Modal, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { NoResult } from 'NoResult'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import layerService from 'services/layer.service'
import { RootState } from 'store/reducer'
import { ReportViewProps } from './ReportView.interface'
import { style } from './ReportView.style'

let reportViewTimer: NodeJS.Timer

export const ReportView = ({ open, toggle }: ReportViewProps) => {
  const handleClose = () => toggle(false)
  const [, setLayerDetails] = useState({})
  const [generating, setGenerating] = useState(true)
  const [reportUrl, setReportUrl] = useState(null)
  const [reportStatus, setReportStatus] = useState(null)
  const [reportHash, setReportHash] = useState(null)
  const selectedLayer = useSelector(
    (state: RootState) => state.layer.selectedLayer
  )

  const fetchLayerDetails = useCallback(async () => {
    if (selectedLayer.id) {
      setGenerating(true)
      try {
        const response = await layerService.fetchLayerDetails(selectedLayer.id)
        const { eda_report } = response.metaData
        setReportStatus(eda_report.status)
        setReportHash(eda_report.hash)

        setLayerDetails(response)
      } catch (error) {
        console.error(error)
      } finally {
        setGenerating(false)
      }
    }
  }, [selectedLayer.id])

  const generateReport = async () => {
    setGenerating(true)
    try {
      const response = await layerService.generateReport(selectedLayer.id)

      const { eda_report } = response.publishStatus
      setReportStatus(eda_report.status)
      setReportHash(eda_report.hash)
    } catch (error) {
      console.error(error)
    } finally {
      setGenerating(false)
    }
  }

  const getReportUrl = useCallback(async () => {
    const { reportURL } = await layerService.fetchReportDocument(reportHash)
    setReportUrl(reportURL)
  }, [reportHash])

  useEffect(() => {
    // when is open
    if (open) {
      if (reportViewTimer) {
        clearTimeout(reportViewTimer)
      }
      reportViewTimer = setTimeout(() => {
        fetchLayerDetails()
      }, 500)
    }
  }, [fetchLayerDetails, open])

  useEffect(() => {
    if (reportHash) {
      getReportUrl()
    }
  }, [getReportUrl, reportHash])

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={style}
          width={reportUrl ? '100%' : 'auto'}
          height={reportUrl ? '100%' : 'auto'}
        >
          <LoadingProgress loading={generating} />
          {reportStatus === 'REPORT_GENERATE_PENDING' && (
            <Typography variant="h5" style={{ textAlign: 'center' }}>
              جاري إنشاء التقرير، قد تستغرق هذي العملية بعض الوقت. يمكنك إغلاق
              الصفحة و التحقق لاحقاً
            </Typography>
          )}
          {reportStatus !== 'REPORT_GENERATE_PENDING' && !reportHash && (
            <div style={{ width: '100%' }}>
              <NoResult
                title="لا يوجد تقارير"
                message="يمكنك إنشاء تقرير بالضغط على الزر أدنى"
                button={
                  <Button
                    onClick={generateReport}
                    variant="contained"
                    disableElevation
                    size="large"
                    color="primary"
                  >
                    إنشاء التقرير الاستكشافي
                  </Button>
                }
              />
            </div>
          )}
          {reportUrl && (
            <div style={{ width: '100%', height: '100%' }}>
              <Typography style={{ textAlign: 'center' }} variant="h5">
                التقرير الاستكشافي
              </Typography>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  onClick={generateReport}
                  variant="contained"
                  disableElevation
                  size="large"
                  color="primary"
                  style={{ marginBottom: 10 }}
                >
                  إعادة إنشاء التقرير الاستكشافي
                </Button>
                <Button variant="contained" color="secondary" disableElevation>
                  <CloseIcon fontSize="small" /> إغلاق
                </Button>
              </div>

              <iframe
                src={reportUrl}
                title="EDA Report"
                frameBorder="0"
                style={{ height: '100%', width: '100%' }}
              />
            </div>
          )}
        </Box>
      </Modal>
    </div>
  )
}
