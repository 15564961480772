import { Grid, IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { FormRowActionButtonsProps } from './FormRow.interface'
import { useStyle } from './FormRow.style'

export const FormRowActionButtons = ({
  enableAdd,
  rowIndex,
  enableRemove,
  onAppend,
  onRemove,
}: FormRowActionButtonsProps) => {
  const classes = useStyle()
  return (
    <Grid item xs={1}>
      <IconButton
        className={[classes.actionIcon].join(' ')}
        size="small"
        disabled={!enableAdd}
        style={{ visibility: enableAdd ? 'visible' : 'hidden' }}
        onClick={onAppend}
      >
        <AddIcon />
      </IconButton>
      <IconButton
        className={[classes.actionIcon].join(' ')}
        size="small"
        style={{ visibility: enableRemove ? 'visible' : 'hidden' }}
        onClick={() => onRemove(rowIndex)}
      >
        <RemoveIcon />
      </IconButton>
    </Grid>
  )
}
