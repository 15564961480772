import { gql } from '@apollo/client'

export const RJFS_ORDER_TEMPLATE = gql`
  query GetOrderTemplate($orderType: String!, $orgId: ID!) {
    orderTemplate(orderType: $orderType, orgId: $orgId) {
      id
      initForm {
        formSchema
        id
        name
        webUiSchema
      }
      orderType
      structure
    }
  }
`

export const RJFS_FORM_DETAILS = gql`
  query GetFormDetails($formId: ID!, $orgId: ID!, $orderId: ID!) {
    form(formId: $formId, orgId: $orgId, orderId: $orderId) {
      formSchema
      id
      name
      webUiSchema
    }
  }
`

export const RJFS_FORM_DATA = gql`
  query getFormData($id: ID!) {
    formData(id: $id) {
      data
      form {
        formSchema
        id
        name
        webUiSchema
      }
      id
      order {
        id
        structure
      }
    }
  }
`

export const RJFS_ORDERS = gql`
  query GetOrders {
    orders {
      id
      structure
    }
  }
`
