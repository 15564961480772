import React from 'react'

import { Typography, CircularProgress } from '@material-ui/core'

import WarningIcon from '@material-ui/icons/Warning'

import clsx from 'clsx'

import { CreateLayerButton } from 'components'

import { useStyle } from './NoResult.style'

import { NoResultProps } from './NoResult.interface'

const texts = {
  EMPTY: {
    title: 'لم يتم إنشاء آي طبقة',
    message: 'يرجى إنشاء طبقة جديدة للبدا باستخدام تتبع',
  },
  LOADING: {
    title: 'برجاء الإنتظار',
    message: 'جاري تحميل البيانات المطلوبة',
  },
}

export const NoResult = ({
  open = true,
  status = 'EMPTY',
  message,
  title,
  button,
}: NoResultProps) => {
  const classes = useStyle()
  const isLoading = status === 'LOADING'

  return (
    <div className={clsx(classes.root, { [classes.hide]: !open })}>
      <CircularProgress className={clsx({ [classes.hide]: !isLoading })} />
      <WarningIcon
        className={clsx(classes.warningIcon, { [classes.hide]: isLoading })}
        fontSize="large"
      />
      <Typography className={classes.title}>
        {title || texts[status].title}
      </Typography>
      <Typography className={classes.message}>
        {message || texts[status].message}
      </Typography>
      {button || (
        <CreateLayerButton className={clsx({ [classes.hide]: isLoading })} />
      )}
    </div>
  )
}
