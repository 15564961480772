import { UserPermissions } from 'global'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/reducer'

type PermissionKey = keyof typeof UserPermissions

export const useOrg = () => {
  const {
    activeOrg = {} as any,
    orgs,
    loadingOrgs,
  } = useSelector((state: RootState) => state.org)

  const permissions = useMemo(() => {
    return activeOrg.userPermissions || []
  }, [activeOrg])

  const hasPermissionTo = useCallback(
    (key: PermissionKey) => {
      return permissions.includes(UserPermissions[key])
    },
    [permissions]
  )

  return {
    organizations: orgs,
    activeOrg,
    loadingOrgs,
    permissions,
    hasPermissionTo,
  }
}
