import { FC, useMemo } from 'react'
import clsx from 'clsx'
import { StyleInjector } from 'components'
import { HideProps } from './Hide.interface'
import { useStyle } from './Hide.style'

export const Hide: FC<HideProps> = ({
  element,
  when,
  children,
  clone,
}: HideProps) => {
  const classes = useStyle()
  const Component: any = useMemo(() => element, [element])
  const hide = useMemo(() => {
    if (Array.isArray(when)) {
      return when.some((e) => e)
    }
    return when
  }, [when])

  if (clone) {
    return (
      <StyleInjector classes={clsx({ [classes.hide]: hide })}>
        {children}
      </StyleInjector>
    )
  }

  return (
    <Component className={clsx({ [classes.hide]: hide })}>{children}</Component>
  )
}

Hide.defaultProps = {
  element: 'div',
}
