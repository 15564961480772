import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    border: 'solid 1px #dfdfdf',
    background: 'white',
    marginBottom: theme.spacing(1),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  headerTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#16151a',
  },
  headerIcon: {},
  body: {
    padding: theme.spacing(2),
  },
}))
