import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    minHeight: 300,
    border: '1px solid #ccc',
    borderRadius: 10,
  },
  warningIcon: {
    color: '#616060',
    fontSize: theme.spacing(8),
  },
  title: {
    fontSize: 17,
    fontWeight: 'bold',
    margin: theme.spacing(0.5, 0),
  },
  message: {
    margin: theme.spacing(0.5, 0),
    fontSize: 15,
  },
  hide: {
    display: 'none',
  },
}))
