// @ts-nocheck
/* global LeafletMap */
import { getToken } from '@flint/auth'
import { DefaultOptions, ILayer, IRecord } from 'global'
import L, { Map, Popup as PopupProps } from 'leaflet'
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Popup } from 'react-leaflet'
import { cloneDeep } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/reducer'
import { GeoTechWms } from 'utils/GeoTechWms'
import {
  reProjectGeoJSONTo4326,
  parseGeoServerCRS,
  epsg5387,
} from 'utils/geometry'
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import RightIcon from '@material-ui/icons/KeyboardArrowRight'
import { RecordPopup } from 'components'
import * as rxjs from 'rxjs'
import layerService from 'services/layer.service'
import { Button, Divider, IconButton } from '@material-ui/core'
import { createLayerAction, setActiveLayer } from 'store/layers'
import { useOrg } from 'hooks'
import { goToLayer } from 'utils/common'
import { useLocation } from 'react-router-dom'
import { DrawShapes, RefetchControl, LayersFeature } from 'containers'
import { GeoMap } from '.'
import { LayersLegend } from './LayersLegend'
import { GeoSearchElement } from './GeoSearchElement'

const { Subject } = rxjs

enum LayersGroup {
  'GEOTECH' = 'GEOTECH',
  'TATABAA' = 'TATABAA',
}

const URL = process.env.REACT_APP_API_BASE_URL

let _getAuthTokenTimer
export const GeoServerMap = memo(() => {
  const [accessToken, setAccessToken] = useState(null)
  const routerLocation = useLocation()
  const [popupPosition, setPopupPosition] = useState<any>()
  const [currentFeatures, setCurrentFeatures] = useState({})
  const [abort] = useState<rxjs.Subject<any>>(new Subject())
  const [map, setMap] = useState<Map>()
  const popupRef = useRef<PopupProps>()
  const [selectedPopupIndex, setSelectedPopupIndex] = useState(0)
  const dispatch = useDispatch()
  const { activeOrg } = useOrg()
  const { layer, flint } = useSelector((state: RootState) => state)
  const fetchAccessToken = useCallback(async () => {
    try {
      const token = getToken()
      setAccessToken(token)
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (map) {
      setTimeout(() => {
        map.invalidateSize()
      }, 400)
    }
  }, [flint.layout.isDetailsWrapperOpen, flint.layout.isDrawerOpen, map])

  useEffect(() => {
    if (_getAuthTokenTimer) {
      clearTimeout(_getAuthTokenTimer)
    }
    _getAuthTokenTimer = setTimeout(() => {
      fetchAccessToken()
    }, 500)
    return () => {
      clearTimeout(_getAuthTokenTimer)
    }
  }, [fetchAccessToken])

  const { layers, checkedLayers, updatedRecord, selectedLayer, selectdRecord } =
    layer

  const gtLayerNames: Array<string> = useMemo(
    () =>
      checkedLayers
        .filter((layer: ILayer) => layer.isGtLayer)
        .map((layer: ILayer) => {
          return layer.wmsFeaturetypeName
        }),
    [checkedLayers]
  )
  const layerNames: Array<string> = useMemo(
    () =>
      checkedLayers
        .filter((layer: ILayer) => !layer.isGtLayer)
        .map((layer: ILayer) => {
          return layer.wmsFeaturetypeName
        }),
    [checkedLayers]
  )

  const whenCreated = useCallback((_map: LeafletMap) => {
    setMap(_map)
    // when map is created
    const el = document.getElementById('geo-server-map')

    const mapHeight =
      window.innerHeight - el.offsetTop - window.innerHeight * 0.15
    el.style.height = `${mapHeight}px`
    el.style.zIndex = 0
  }, [])
  const geoTechWmsParams = useMemo(() => {
    if (!activeOrg) return {}
    const params = {
      EXCEPTIONS: 'application/vnd.ogc.se_xml',
      format: 'image/png8',
      infoFormat: 'application/json',
      transparent: true,
      uppercase: true,
      tiled: false,
      organization: activeOrg.id,
    }
    const _params = {}
    Object.keys(params).forEach((key: string) => {
      _params[key.toUpperCase()] = params[key]
    })
    return params
  }, [activeOrg])

  const onSuccess = useCallback(
    (response, e: any, layerGroup: LayersGroup) => {
      if (!checkedLayers.length) return
      if (layerGroup === LayersGroup.TATABAA) {
        const { features, crs } = response || {}
        const _features = (features || []).map((f: any) => {
          let geom = f.geometry
          if (parseGeoServerCRS(crs) === epsg5387) {
            // re-project geometry
            geom = reProjectGeoJSONTo4326(f.geometry)
          }
          const layerName = f.id.split('.')[0]
          const layer = layers.find((layer: ILayer) => {
            return layer.name === layerName
          })
          const recordId = f.properties.id
          const __featureClone = { ...f }
          return {
            ...__featureClone,
            geometry: geom,
            id: recordId,
            layer,
          }
        })
        setCurrentFeatures({ layerGroup, features: _features })
        let latlng: any = e.latlng
        if (!_features.length) {
          latlng = null
        }
        setSelectedPopupIndex(0)
        setPopupPosition(latlng)
      }
    },
    [layers, checkedLayers.length]
  )

  useEffect(() => {
    if (updatedRecord && Object.keys(updatedRecord).length) {
      setCurrentFeatures((prev) => {
        const __clone = cloneDeep(prev)
        if (
          !Array.isArray(__clone.features) ||
          !Object.keys(updatedRecord).length
        ) {
          return prev
        }
        const featureIndex = __clone.features.findIndex(
          (f: any) => f.id === parseInt(updatedRecord.id, 10)
        )
        if (featureIndex !== -1) {
          __clone.features[featureIndex].properties = {
            ...updatedRecord,
            data: JSON.stringify(updatedRecord.data),
          }
          return __clone
        }
        return prev
      })
    }
  }, [updatedRecord])

  const abortRequest = useCallback(() => {
    if (abort) {
      abort.next()
    }

    return () => {
      abort.unsubscribe()
    }
  }, [abort])

  const onRefresh = () => {
    const checklyrs = checkedLayers.map((c) => c)
    dispatch(createLayerAction('checkedLayers', []))
    setTimeout(() => {
      dispatch(createLayerAction('checkedLayers', checklyrs))
    }, 500)
  }
  // this function called when user selects a record from the table and initiating the popup with the required data
  useEffect(() => {
    if (
      Object.keys(selectedLayer).length > 0 &&
      Object.keys(selectdRecord).length > 0
    ) {
      if (!selectdRecord?.geometry?.geometries[0]) return
      const { coordinates, type } = selectdRecord.geometry.geometries[0]

      // to get the layer which have its records
      const layer = layers.find((layer: ILayer) => {
        return layer.id === selectedLayer.id
      })
      setCurrentFeatures({
        layerGroup: 'TATABA',
        features: [{ ...selectdRecord, layer }],
      })
      switch (type) {
        case 'Point':
          setPopupPosition({ lat: coordinates[1], lng: coordinates[0] })
          break
        case 'LineString':
          setPopupPosition({
            lat: coordinates[0][1],
            lng: coordinates[0][0],
          })
          break
        case 'Polygon':
          setPopupPosition({
            lat: coordinates[0][0][1],
            lng: coordinates[0][0][0],
          })
          break
        default:
          break
      }

      setSelectedPopupIndex(0)
    }
  }, [selectedLayer, selectdRecord])
  useEffect(() => {
    dispatch(createLayerAction('map', map))
  }, [map])

  useEffect(() => {
    if (map) {
      map.on('movestart', abortRequest)
      map.on('zoomstart', abortRequest)
    }
    return () => {
      if (map) {
        map.removeEventListener('movestart', abortRequest)
        map.removeEventListener('zoomstart', abortRequest)
      }
    }
  }, [map, abortRequest])

  const selectedFeature = useMemo(() => {
    if (currentFeatures.features && currentFeatures.features.length) {
      return currentFeatures.features[selectedPopupIndex]
    }
  }, [selectedPopupIndex, currentFeatures])

  const showOnTable = async (record: IRecord) => {
    const { id, layer } = record

    const pageNo = await layerService.queryPageNumber({
      layer: layer.id,
      record: id,
      limit: DefaultOptions.RECORD_LIMIT,
    })
    // if we found a page number, set the page number
    if (pageNo) {
      dispatch(createLayerAction('highlightRecord', record.id.toString()))
      dispatch(setActiveLayer(record.layer))
      dispatch(createLayerAction('recordsPage', pageNo))
      goToLayer(record.layer, `?highlight=${record.id}`)
    }
  }

  // listen on bounries changes
  const onMoveEnd = useCallback(() => {
    if (!map) return
    const url = new window.URL(window.location.href)
    const mapCenter = map.getCenter()
    const latlngStr = [
      mapCenter.lat.toString(),
      mapCenter.lng.toString(),
    ].join()
    url.searchParams.set('mapCenter', latlngStr)
    url.searchParams.set('mapZoom', map.getZoom())
    window.history.pushState(null, null, url.href)
  }, [map])

  useEffect(() => {
    if (map) {
      map.on('dragend', onMoveEnd)
      map.on('zoomend', onMoveEnd)
    }
    return () => {
      if (map) {
        map.off('dragend', onMoveEnd)
        map.off('zoomend', onMoveEnd)
      }
    }
  }, [map, onMoveEnd])

  // set boundires from the url
  const setMapCenter = useCallback(() => {
    if (routerLocation && routerLocation.search && map) {
      const params = new URLSearchParams(routerLocation.search)
      if (params.has('mapCenter')) {
        const mapCenter = params.get('mapCenter')
        const mapZoom = parseInt(params.get('mapZoom'), 10) || 5
        const mapCenterArr = mapCenter.split(',')
        if (mapCenterArr.length < 2) {
          return
        }
        const [lat, lng] = mapCenterArr
        const point = L.latLng(parseFloat(lat), parseFloat(lng))
        map.setView(point, mapZoom)
      }
    }
  }, [map, routerLocation])
  useEffect(() => {
    setMapCenter()
  }, [setMapCenter])

  useEffect(() => {
    popupRef?.current?._closeButton?.addEventListener('click', (e) =>
      e.preventDefault()
    )
  }, [popupRef, popupPosition, selectedFeature])

  if (!accessToken || !activeOrg) return null

  return (
    <Fragment>
      <GeoMap whenCreated={whenCreated} id="geo-server-map" maxZoom={25}>
        <LayersFeature />

        {popupPosition && selectedFeature && (
          <Popup ref={popupRef} position={popupPosition} minWidth={300}>
            <RecordPopup
              record={selectedFeature}
              layer={selectedFeature.layer}
            />
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button onClick={() => showOnTable(selectedFeature)}>
                عرض في الجدول
              </Button>
              <div>
                <IconButton
                  disableRipple
                  disableTouchRipple
                  size="small"
                  disabled={!selectedPopupIndex}
                  style={{
                    visibility: !selectedPopupIndex ? 'hidden' : 'visible',
                  }}
                  onClick={() => setSelectedPopupIndex(selectedPopupIndex - 1)}
                >
                  <RightIcon />
                </IconButton>
                <strong>{selectedPopupIndex + 1}</strong> من{' '}
                <strong>{currentFeatures.features.length}</strong>
                <IconButton
                  disableRipple
                  disableTouchRipple
                  size="small"
                  onClick={() => setSelectedPopupIndex(selectedPopupIndex + 1)}
                  style={{
                    visibility: !(
                      selectedPopupIndex + 1 <
                      currentFeatures.features.length
                    )
                      ? 'hidden'
                      : 'visible',
                  }}
                  disabled={
                    !(selectedPopupIndex + 1 < currentFeatures.features.length)
                  }
                >
                  <LeftIcon />
                </IconButton>
              </div>
            </div>
          </Popup>
        )}

        {layerNames.length && (
          <GeoTechWms
            abort={abort}
            layerGroup={LayersGroup.TATABAA}
            onSuccess={onSuccess}
            params={{ ...geoTechWmsParams, layers: layerNames }}
            url={`${URL}/proxy/organization-wms/`}
            headers={{ authorization: `Bearer ${accessToken}` }}
            maxZoom={25}
          />
        )}
        {gtLayerNames.length && (
          <GeoTechWms
            abort={abort}
            onSuccess={onSuccess}
            layerGroup={LayersGroup.GEOTECH}
            params={{
              ...geoTechWmsParams,
              layers: gtLayerNames.join(','),
            }}
            url={`${URL}/proxy/geotech-wms/`}
            headers={{ authorization: `Bearer ${accessToken}` }}
            maxZoom={25}
          />
        )}
        <DrawShapes />
        {/* <MapLoader loading={loading} /> */}
        <RefetchControl onRefresh={onRefresh} />
        <GeoSearchElement />
      </GeoMap>
      <LayersLegend />
    </Fragment>
  )
})
