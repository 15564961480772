import { LayersControl, LayerGroup } from 'react-leaflet'

import { memo } from 'react'
import { SingleLayerControlProps } from './LayersFeature.interface'

export const SingleLayerControl = memo(({ layer }: SingleLayerControlProps) => {
  return (
    <LayersControl.Overlay name={layer.title} key={layer.id} checked>
      <div
        style={{ display: 'none' }}
        className="layer_control_details_storage"
      />
      <LayerGroup />
    </LayersControl.Overlay>
  )
})
