import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export const useStyle = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    textAlign: 'center',
    position: 'relative',
    backgroundColor: '#fff',
    padding: theme.spacing(4, 10),
    borderRadius: theme.spacing(1.3),
  },
  message: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: theme.spacing(3.5),
  },
  confirmButton: {
    fontSize: 15,
    fontWeight: 'bold',
    padding: theme.spacing(1, 4),
  },
  clearIcon: {
    top: 20,
    right: 20,
    width: 30,
    height: 30,
    color: '#d3d3d3',
    cursor: 'pointer',
    position: 'absolute',
  },
}))
