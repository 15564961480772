import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  layerCard: {
    width: 300,
    height: 300,
    backgroundColor: 'white',
    boxShadow: ' 0px 7px 22px #0000001A',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: 10,
    padding: theme.spacing(3),
    textAlign: 'center',
  },
  layerCardTitle: {
    color: theme.palette.primary.main,
    fontSize: 23,
    fontWeight: 'bold',
    margin: theme.spacing(2, 0),
  },
  layerCardIcon: {
    width: 52,
    height: 52,
  },
  layerCardButton: {
    fontWeight: 'bold',
    padding: theme.spacing(2, 0),
    fontSize: 20,
    borderRadius: 10,
  },
  layerCardText: {
    fontSize: 15,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2),
  },
}))
