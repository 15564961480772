import { Typography, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import RightArrow from '@material-ui/icons/KeyboardArrowRight'
import LeftArrow from '@material-ui/icons/KeyboardArrowLeft'
import clsx from 'clsx'
import { useStyle } from './FormHeader.style'
import { FormHeaderProps } from './FormHeader.interface'

export const FormHeader = ({
  totalSteps,
  activeStepNumber = 0,
  title,
  onClose,
  onChangeStep,
}: FormHeaderProps) => {
  const classes = useStyle()

  const goNext = () => {
    if (activeStepNumber < totalSteps - 1) {
      onChangeStep(activeStepNumber + 1)
    }
  }

  const goPrev = () => {
    if (activeStepNumber) {
      onChangeStep(activeStepNumber - 1)
    }
  }

  return (
    <div className={classes.modalHeader}>
      <Typography className={classes.modalHeaderTitle}>{title}</Typography>
      <div
        className={clsx(classes.stepsCounter, {
          [classes.hide]: activeStepNumber === 0,
        })}
      >
        <div className={classes.dotsWrapper}>
          <IconButton
            onClick={goPrev}
            size="small"
            className={clsx({ [classes.hide]: activeStepNumber < 1 })}
          >
            <LeftArrow />
          </IconButton>
          {Array(totalSteps)
            .fill(0)
            .map((_, index) => {
              return (
                <div
                  key={index}
                  onClick={() => onChangeStep(index)}
                  className={clsx(classes.dot, {
                    [classes.acitve]: index === activeStepNumber,
                  })}
                />
              )
            })}
          <IconButton
            onClick={goNext}
            size="small"
            className={clsx({
              [classes.hide]: activeStepNumber + 1 === totalSteps,
            })}
          >
            <RightArrow />
          </IconButton>
        </div>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </div>
      <IconButton
        onClick={onClose}
        size="small"
        className={clsx({ [classes.hide]: activeStepNumber > 0 })}
      >
        <CloseIcon />
      </IconButton>
    </div>
  )
}
