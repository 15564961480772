// @ts-nocheck
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { useField } from 'formik'
import { GSelectProps } from './GFSelect.interface'
import { BootstrapInput, useStyles } from './GFSelect.style'

export const GFSelect = ({
  label,
  name,
  items,
  placeholder,
  children,
  value,
  formControlProps = { fullWidth: true },
  ...rest
}: GSelectProps) => {
  const classes = useStyles()
  const [field] = useField<any>({ name, value, type: 'select' })
  return (
    <FormControl className={classes.root} {...formControlProps}>
      <InputLabel shrink={false} className={classes.label}>
        {label}
      </InputLabel>
      <Select
        fullWidth
        displayEmpty={!!placeholder}
        input={<BootstrapInput className={label ? classes.inputRoot : ''} />}
        {...rest}
        {...field}
      >
        {placeholder && (
          <MenuItem value="" disabled key="abc">
            {placeholder}
          </MenuItem>
        )}
        {children ||
          items.map((item: any) => {
            const text = item && item.hasOwnProperty('text') ? item.text : item
            const itemValue =
              item && item.hasOwnProperty('value') ? item.value : item
            return (
              <MenuItem key={itemValue} value={itemValue}>
                {text}
              </MenuItem>
            )
          })}
      </Select>
    </FormControl>
  )
}
