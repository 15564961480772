import { TableCell, TableCellProps } from '@material-ui/core'
import { useStyle } from './GeoTable.style'

export const GeoTableCustomBodyCell = (props: TableCellProps) => {
  const classes = useStyle()
  return (
    <TableCell className={classes.customBodyCell} {...props}>
      {props.children && <strong>{props.children}</strong>}
    </TableCell>
  )
}
