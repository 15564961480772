/* global GeoJSON */
import { SchemaBuilderViews, FormMode, ILayer, IRecord } from 'global'
import { Layer, Popup, Map } from 'leaflet'

export interface ILayerForm {
  step: number
  isActive: boolean
  action?: LayerFormAction
  source?: 'regular' | 'dataset'
  isFileUploaded?: boolean
  isGeoJson?: boolean
  fileUploadResponse?: any
  responseJsonSchema?: any
  geoType?: 'latlng' | 'wkt'
  wktColumn?: string
  lonLatColumns?: { latitude: string; longitude: string }
}
export enum LayerFormAction {
  UPDATE = 'update',
  CREATE = 'create',
}
export interface ILayerState {
  map?: Map
  tabView?: SchemaBuilderViews
  isAdvancedSchema: boolean
  updatedRecord?: IRecord
  layers: ILayer[]
  selectedLayer: ILayer
  selectdRecord?: IRecord
  selectedPoint?: string
  selectedGeoJson?: GeoJSON.Geometry
  uiSchema?: Record<string, any>
  formData?: Record<string, any>
  loadingLayers: boolean
  layerForm: ILayerForm
  records: IRecord[]
  recordFormMode: FormMode
  serverError?: any
  newLayerLoader: boolean
  changeLocationMode?: boolean
  loadingMessage: string
  loadingRecords: boolean
  bboxLoadingRecords: boolean
  deletingRecord: boolean
  deletingLayer: boolean
  geoTechLayers: ILayer[]
  isSearchMode: boolean
  layersUpdated: boolean
  locationForUpdate?: any
  wmsLayers?: Record<string, Layer>
  lfLayer?: Layer
  openedPopup?: Popup
  isInfinitScroll?: boolean
  highlightRecord?: number | string
  recordsPage: number
  checkedLayers: Array<{
    id: number
    isGtLayer: boolean
  }>
}

export const layerReducer = (): ILayerState => ({
  tabView: SchemaBuilderViews.UI_VIEW,
  layers: [],
  recordsPage: 1,
  updatedRecord: {} as any,
  geoTechLayers: [],
  bboxLoadingRecords: false,
  selectedLayer: {} as ILayer,
  selectdRecord: {} as IRecord,
  deletingRecord: false,
  deletingLayer: false,
  uiSchema: {},
  formData: {},
  isInfinitScroll: false,
  wmsLayers: {},
  records: [],
  checkedLayers: [],
  isSearchMode: false,
  loadingLayers: false,
  loadingRecords: false,
  layersUpdated: false,
  loadingMessage: '',
  changeLocationMode: false,
  recordFormMode: FormMode.VIEW,
  newLayerLoader: false,
  isAdvancedSchema: false,
  layerForm: {
    source: 'regular',
    step: 0,
    isActive: false,
    action: LayerFormAction.CREATE,
    responseJsonSchema: {},
    geoType: 'wkt',
    wktColumn: '',
    lonLatColumns: { latitude: '', longitude: '' },
  },
})

export default layerReducer
