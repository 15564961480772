import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import { Field, useField } from 'formik'
import { GtTextField } from './GFInput.style'

export const GFInput = ({ id, label, type, styles, ...props }: any) => {
  const [, meta] = useField(props)
  const { error, touched } = meta
  const errorText = touched && error
  return (
    <FormControl style={{ width: '100%' }} hiddenLabel={type === 'hidden'}>
      <InputLabel
        htmlFor={id}
        shrink
        style={{
          fontSize: 15,
          fontWeight: 'bold',
          color: '#16151a',
        }}
      >
        {label}
      </InputLabel>
      <Field
        as={GtTextField}
        id={id}
        style={{ ...styles }}
        error
        type={type}
        {...props}
      />
      <FormHelperText error={!!errorText}>{errorText || null}</FormHelperText>
    </FormControl>
  )
}
