import { IOrgState } from './org.reducer'

const reducer = 'org'

export const setOrgState = (type: keyof IOrgState, payload: any) => ({
  reducer,
  type,
  payload,
})
export default setOrgState
