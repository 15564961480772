import { gql } from '@apollo/client'

export const FETCH_ORG = gql`
  query FetchOrg {
    organizationsList {
      id
      userPermissions
      userRoles
      name
      meta
      fullLogo
      abstractLogo
    }
  }
`

export const FETCH_ORGANIZATION_DETAILS = gql`
  query OrganizationDetails($id: Int!) {
    organizationDetails(id: $id) {
      fullLogo
      name
      userPermissions
      userRoles
    }
  }
`

export const FETCH_LAYERS = gql`
  query FetchLayers($orgId: Int!, $filters: LayersFiltersInputType) {
    layersList(orgId: $orgId, filters: $filters) {
      id
      description
      esIndexName
      wmsFeaturetypeName
      webUiJsonSchema
      userRoles
      userPermissions
      title
      status
      recordsCount
      name
      mobileUiJsonSchema
      metaData
      jsonSchema
      geometryColor
    }
  }
`
export const FETCH_REMOTE_LAYERS = gql`
  query FetchRemoteLayers(
    $orgId: Int!
    $filters: RemoteLayersFiltersInputType
  ) {
    remoteLayersList(orgId: $orgId, filters: $filters) {
      description
      geometryColor
      id
      jsonSchema
      mobileUiJsonSchema
      status
      title
      webUiJsonSchema
      wmsFeaturetypeName
    }
  }
`
export const FETCH_RECORDS = gql`
  query FetchRecords(
    $layerId: Int!
    $filters: LookupsInputType
    $pageInfo: PageInfoInputType
  ) {
    recordsList(layerId: $layerId, filters: $filters, pageInfo: $pageInfo) {
      id
      createdAt
      data
      geometry
      updatedAt
      layer {
        geometryColor
        id
        jsonSchema
        metaData
        mobileUiJsonSchema
        name
        recordsCount
        status
        title
        userPermissions
        userRoles
        webUiJsonSchema
        wmsFeaturetypeName
        description
        esIndexName
      }
    }
  }
`

export const FETCH_USER_DETAILS = gql`
  query FetchUserDetails($userId: ID!, $orgId: ID!) {
    userDetails(userId: $userId, orgId: $orgId) {
      organizations
      email
      firstName
      id
      lastName
      phone
    }
  }
`

export const LAYER_DETAILS = gql`
  query LayerDetails($layerId: Int!) {
    layerDetails(layerId: $layerId) {
      description
      esIndexName
      geometryColor
      geometryType
      id
      jsonSchema
      metaData
      mobileUiJsonSchema
      name
      recordsCount
      title
      webUiJsonSchema
      wmsFeaturetypeName
    }
  }
`

export const RECORD_PAGE_NUMBER = gql`
  query RecordPageNumber($paginationInput: PaginationInput) {
    recordsPagination(paginationInput: $paginationInput) {
      pageNumber
    }
  }
`
