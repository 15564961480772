import { getMappedReducers } from '@flint/utils'
import { formReducer } from './form'
import { layerReducer } from './layers/layer.reducer'
import { layoutReducer } from './layout/layout'
import { orgReducer } from './org/org.reducer'

export const plainReducers = getMappedReducers({
  prefix: '',
  reducers: {
    org: orgReducer,
    form: formReducer,
    layer: layerReducer,
    layout: layoutReducer,
  },
})
