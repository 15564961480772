// @ts-nocheck
import {
  ReactJsonSchemaForm,
  IReactJsonSchemaFormProps,
  useFromButtons,
} from '@flint/rjsf'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/reducer'
import { FormMode } from 'global'
import { buildUiSchema } from 'utils/jsonSchema'
import { isEqual } from 'lodash'
import { useState, useMemo, useEffect, useCallback } from 'react'
import {
  setActiveRecord,
  setViewMode,
  toggleChangeLocationMode,
} from 'store/layers/layer.actions'
import { ISubmitEvent } from '@rjsf/core'
import NexIcon from '@material-ui/icons/KeyboardArrowLeft'
import PrevIcon from '@material-ui/icons/KeyboardArrowRight'
import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import LayersIcon from '@material-ui/icons/Layers'
import { MainButton } from '@flint/core'
import clsx from 'clsx'
import {
  updateRecord,
  createRecord,
  deleteRecord,
} from 'store/layers/layer.async'
import { simplifyCoord } from 'utils/geometry'
import { ConfirmDialog } from 'components'
import layerService from 'services/layer.service'
import { useOrg } from 'hooks'
import L from 'leaflet'
import { goToLayer } from 'utils/common'
import useStyle from './RecordFormSchema.style'

const { parse } = require('wkt')

const mapGeoType: Record<string, string> = {
  point: 'النقطة',
  polygon: 'المضلع',
  linestring: 'الخط',
}

export const RecordFormSchema = () => {
  const classes = useStyle()
  const dispatch = useDispatch()
  const [isDialogOpen, setDialogOpen] = useState(false)
  const { activeOrg } = useOrg()
  const [submitAction, setSubmitAction] = useState<
    'NEXT' | 'PREV' | 'SUBMIT' | null
  >(null)
  const [formData, setFormData] = useState<any>(undefined)
  const {
    selectedLayer,
    selectdRecord,
    recordFormMode,
    selectedPoint,
    changeLocationMode,
    locationForUpdate,
    loadingRecords,
    map,
  } = useSelector((state: RootState) => state.layer)
  const readonly = recordFormMode === FormMode.VIEW
  const uiSchema = useMemo(() => {
    if (
      selectedLayer.webUiJsonSchema &&
      Object.keys(selectedLayer.webUiJsonSchema).length
    ) {
      return selectedLayer.webUiJsonSchema
    }
    return buildUiSchema(selectedLayer.jsonSchema)
  }, [selectedLayer])

  const schema = (selectedLayer && selectedLayer.jsonSchema) || {}

  const selectedRecrodData = useMemo(() => {
    return (
      selectdRecord.data ||
      (selectdRecord.properties && JSON.parse(selectdRecord.properties.data)) ||
      {}
    )
  }, [selectdRecord])
  useEffect(() => {
    setFormData(selectedRecrodData)
  }, [selectedRecrodData])
  const { defulatButtonProps } = useFromButtons()
  const isCreate = recordFormMode === FormMode.CREATE
  const isView = recordFormMode === FormMode.VIEW

  const getGeometry = () => {
    let geometry = null
    if (changeLocationMode && locationForUpdate) {
      geometry = parse(locationForUpdate)
    } else if (selectdRecord?.geometry) {
      geometry = selectdRecord?.geometry?.geometries[0]
    } else if (isCreate && selectedPoint) {
      geometry = parse(selectedPoint)
    }

    return geometry
  }

  // this is used to show the current selected coordinates as string on the record details view
  const activedPoint = useMemo(() => {
    if (getGeometry()) {
      return simplifyCoord(getGeometry().coordinates)
    }
  }, [
    selectdRecord,
    selectedPoint,
    isCreate,
    changeLocationMode,
    locationForUpdate,
  ])

  const onSeconderyButtonClicked = () => {
    dispatch(setViewMode())
  }

  const secondaryButtonProps = useMemo(() => {
    const text = 'إلغاء'
    return {
      type: 'button',
      children: text,
      color: 'secondary',
    }
  }, [])

  const onSubmit = useCallback(
    async ({ formData: newFormData }: ISubmitEvent<any>) => {
      const data = JSON.stringify(newFormData)
      // if the record does not has geometry

      // in case of updating a record
      if (!isCreate) {
        const updatedData: any = {
          // ...selectdRecord,
          data,
          id: selectdRecord?.id,
        }
        if (locationForUpdate) {
          updatedData.geometry = locationForUpdate
        }

        // fire action for saving the data
        dispatch(updateRecord(updatedData, submitAction))
        setSubmitAction(null)
        return
      }
      const newData: any = {
        data,
        layer: selectedLayer.id,
      }
      if (selectedPoint) {
        newData.geometry = selectedPoint
      }
      // createing a new record
      dispatch(createRecord(newData))
    },
    [
      isCreate,
      dispatch,
      locationForUpdate,
      selectdRecord?.id,
      selectedLayer?.id,
      selectedPoint,
      submitAction,
    ]
  )
  const onFileChange = async (data: Array<{ file: File }> | { file: File }) => {
    try {
      if (Array.isArray(data)) {
        const promises = data.map(({ file }) =>
          layerService.uploadViaSignedUrl(
            file,
            activeOrg.id,
            selectedLayer.id,
            layerService.uploadFile
          )
        )
        return await Promise.all(promises)
      } else {
        return await layerService.uploadViaSignedUrl(
          data.file,
          activeOrg.id,
          selectedLayer.id,
          layerService.uploadFile
        )
      }
    } catch (error) {
      console.error(error)
    }
  }

  const chooseLocation = () => {
    dispatch(toggleChangeLocationMode(!changeLocationMode))
  }

  const ChangeLocationButtonProps = useMemo<ButtonProps>(() => {
    return {
      children: 'اختيار على الخريطة',
      color: 'primary',
      disabled: changeLocationMode,
    }
  }, [changeLocationMode])

  const geoType = useMemo(() => {
    const type = (selectedLayer.geometryType || '').toLocaleLowerCase()
    return mapGeoType[type] as any
  }, [selectedLayer])

  const currentRecordIndex = useMemo(() => {
    if (!selectdRecord || !Object.keys(selectdRecord).length) return null
    return selectedLayer.records.findIndex(
      (r) => r.id === String(selectdRecord.id)
    )
  }, [selectedLayer, selectdRecord])

  const nextEnabled = useMemo(() => {
    return currentRecordIndex + 1 < selectedLayer.records.length
  }, [currentRecordIndex, selectedLayer])

  const nextHandler = useCallback(() => {
    // when do not reach the last record
    if (nextEnabled) {
      const _record = selectedLayer.records[currentRecordIndex + 1]
      if (_record) {
        const recordPath = `/record/${_record.id}`
        goToLayer(_record.layer, recordPath)
        dispatch(setActiveRecord(_record))
      }
    }
  }, [dispatch, currentRecordIndex, selectedLayer, nextEnabled])

  const prevHandler = useCallback(() => {
    if (currentRecordIndex) {
      const _record = selectedLayer.records[currentRecordIndex - 1]
      if (_record) {
        const recordPath = `/record/${_record.id}`
        goToLayer(_record.layer, recordPath)
        dispatch(setActiveRecord(_record))
      }
    }
  }, [dispatch, currentRecordIndex, selectedLayer])

  const isDataChanged = useCallback(() => {
    const changed = !isEqual(formData, selectedRecrodData)
    return changed
  }, [selectedRecrodData, formData])

  const nextRecord = useCallback(() => {
    if (loadingRecords || currentRecordIndex === null) return
    if (isDataChanged()) {
      setDialogOpen(true)
      setSubmitAction('NEXT')
      return
    }
    nextHandler()
  }, [nextHandler, loadingRecords, isDataChanged, currentRecordIndex])

  const goToPoint = () => {
    if (!getGeometry()) return null
    const jsonLayer = L.geoJSON(getGeometry())
    map.fitBounds(jsonLayer.getBounds(), {
      animate: true,
      duration: 1,
      maxZoom: 10,
    })
  }

  const prevRecord = useCallback(() => {
    if (!currentRecordIndex) return
    if (isDataChanged()) {
      setDialogOpen(true)
      setSubmitAction('PREV')
      return
    }
    prevHandler()
  }, [currentRecordIndex, prevHandler, isDataChanged])

  const dialogIgnoreFn = useCallback(() => {
    setDialogOpen(false)
  }, [])

  const submitProceed = useCallback(() => {
    setDialogOpen(false)
    onSubmit({ formData } as any)
  }, [formData, onSubmit])

  const __deleteRecord = useCallback(() => {
    dispatch(
      deleteRecord(
        selectdRecord.id,
        selectedLayer.geometryType,
        selectedLayer.id
      )
    )
  }, [selectdRecord, selectedLayer, dispatch])

  const onKeyDown = useCallback(
    (event: any) => {
      const keyCode = event.keyCode

      // left/next
      if (keyCode === 37) {
        nextRecord()
      } else if (keyCode === 39) {
        prevRecord()
        // right / prev
      } else if (keyCode === 8 && FormMode.VIEW === recordFormMode) {
        __deleteRecord()
      }
    },
    [prevRecord, nextRecord, __deleteRecord, recordFormMode]
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  const onClickSubmitButton = ({ action }) => {
    setSubmitAction(action)
  }

  const confirmButtonProps = useMemo(() => {
    return {
      children: 'حفظ',
    }
  }, [])

  const formSchemaProps = useMemo<
    Partial<IReactJsonSchemaFormProps>
  >((): any => {
    const hiddenButtons = []
    if (isView) {
      hiddenButtons.push('reset', 'saveAndProceed')
    }
    if (isCreate) {
      hiddenButtons.push('saveAndProceed')
    }
    return {
      overrideButtons: [
        {
          ...defulatButtonProps,
          children: 'حفظ و الإنتقال للتالي',
          buttonName: 'saveAndProceed',
          onButtonClick: (data: any) =>
            onClickSubmitButton({ ...data, action: 'NEXT' }),
        },
      ] as any,
      hiddenButtons,
      appendButtonsOnly: true,
    }
  }, [isView, isCreate, defulatButtonProps])

  return (
    <div>
      <ConfirmDialog
        ignoreFn={dialogIgnoreFn}
        submitFn={submitProceed}
        isOpen={isDialogOpen}
      />

      <div className={classes.titleWrapper}>
        <LayersIcon />
        <Typography>
          <strong>طبقة</strong>:
          <span className={classes.layerName}>{selectedLayer.title}</span>
        </Typography>
      </div>
      <Grid container justifyContent="space-between">
        <Grid>
          <Button
            style={{ fontWeight: 'bold' }}
            onClick={prevRecord}
            disabled={!currentRecordIndex}
          >
            <PrevIcon />
            السجل السابق
          </Button>
        </Grid>
        <Grid>
          <Button
            style={{ fontWeight: 'bold' }}
            onClick={nextRecord}
            disabled={loadingRecords || !nextEnabled}
          >
            {(loadingRecords && <CircularProgress size={20} />) ||
              'السجل التالي'}
            <NexIcon />
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <div className={classes.pointWrapper}>
        <Typography className={classes.pointTitle}>
          الحقل الجغرافي ({geoType})
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography
            onClick={goToPoint}
            className={clsx({
              [classes.point]: !!activedPoint,
              [classes.inactivePoint]: !activedPoint,
            })}
          >
            {activedPoint || 'غير محدد'}
          </Typography>
          <MainButton
            variant="contained"
            color="primary"
            onClick={chooseLocation}
            className={clsx({ [classes.hide]: readonly || isCreate })}
            {...ChangeLocationButtonProps}
          />
        </Box>
      </div>
      <div className={classes.formSchemaContainer}>
        <ReactJsonSchemaForm
          onSubmit={onSubmit}
          schema={schema}
          actionButtons={{
            onClickResetButton: onSeconderyButtonClicked,
            onClickSubmitButton,
            resetButtonHide: isView,
            confirmButtonHide: isView,
            confirmButtonProps,
            resetButtonProps: secondaryButtonProps as any,
          }}
          uiSchema={uiSchema as any}
          formData={formData}
          disabled={readonly}
          setFormData={setFormData}
          formContext={{ onFileChange }}
          liveOmit
          omitExtraData
          {...formSchemaProps}
        />
      </div>
    </div>
  )
}
