import { useMemo } from 'react'
import { isObject } from '@flint/utils'
import { List, ListItem } from '@material-ui/core'
import { isDataURL } from 'utils/common'
import { ImageSuspense } from './ImageSuspense'
import { GeoTableBodyCellProps } from './GeoTable.interface'

export const GeoTableBodyCell = ({
  isImage,
  value,
  onImageClicked,
}: GeoTableBodyCellProps) => {
  const imgSrc = useMemo(() => {
    return Array.isArray(value) ? value[0] : value
  }, [value])

  if (Array.isArray(value)) {
    return (
      <List>
        {value.map((d) => (
          <ListItem disableGutters dense>
            {isDataURL(d) ? (
              <ImageSuspense onImageClicked={onImageClicked} src={d} />
            ) : (
              d
            )}
          </ListItem>
        ))}
      </List>
    )
  }
  if (isImage) {
    return <ImageSuspense onImageClicked={onImageClicked} src={imgSrc} />
  }

  return <span>{!isObject(value) && value}</span>
}
