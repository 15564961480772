import { FC } from 'react'
import AddIcon from '@material-ui/icons/Add'
import { Button } from '@material-ui/core'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import {
  changeRecordsView,
  createSelectPointAction,
} from 'store/layers/layer.actions'
import { FormMode } from 'global'
import { useLayer } from 'hooks'
import { useStyle } from './CreateRecordsButton.style'
import { CreateRecordsButtonProps } from './CreateRecordsButton.interface'

export const CreateRecordsButton: FC<CreateRecordsButtonProps> = ({
  active,
}: CreateRecordsButtonProps) => {
  const classes = useStyle()
  const dispatch = useDispatch()
  const { userCan } = useLayer()

  const onClick = () => {
    dispatch(createSelectPointAction(''))
    dispatch(changeRecordsView(FormMode.CREATE))
  }

  if (!userCan('ADD_RECORD')) {
    return null
  }

  return (
    <Button
      variant="contained"
      color="primary"
      className={clsx(classes.root, { [classes.hide]: !active })}
      fullWidth
      disableElevation
      onClick={onClick}
    >
      <AddIcon className={classes.icon} />
      إنشاء سجل جديد
    </Button>
  )
}
