import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
    borderBottom: '1px solid #E1DEED',
  },
  layerName: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  pointWrapper: {
    padding: theme.spacing(1, 0),
    borderBottom: '1px solid #E1DEED',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  point: {
    backgroundColor: '#F3F6EB',
    borderRadius: 10,
    fontWeight: 'bold',
    padding: theme.spacing(0.5),
    fontSize: 14,
    cursor: 'pointer',
  },
  inactivePoint: {
    backgroundColor: '#EBEBEB',
    padding: theme.spacing(1, 3),
    borderRadius: 100,
    fontSize: 14,
    color: '#A8A8A8',
    marginRight: theme.spacing(1),
  },
  pointTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  hide: {
    display: 'none',
  },
  formSchemaContainer: {
    backgroundColor: '#fff',
    padding: theme.spacing(2.5),
    border: '1px solid #e1deed',
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },
  warnIcon: {
    fontSize: 50,
    color: theme.palette.warning.main,
  },
}))
export default useStyle
