import { graphQlQuery } from '@flint/graphql'
import { graphql } from 'global'

class UserService {
  async myOrgs() {
    const { data } = await graphQlQuery({ query: graphql.FETCH_ORG as any })
    return data
  }

  async fetchDetails(userId: number, orgId: number) {
    const { data } = await graphQlQuery({
      query: graphql.FETCH_USER_DETAILS as any,
      variables: { orgId, userId },
    })
    return data
  }
}

export default new UserService()
