import { useSelector } from 'react-redux'
import { RootState } from 'store/reducer'
import { NewLayerFrom } from '../LayerDetails'
import { LayerFormModal } from '../LayerFormModal'

// An array of the steps of the form creation
const steps = [
  // step for adding layer details
  { title: 'تعديل بيانات الطبقة', Component: NewLayerFrom },
]

export const EditLayer = () => {
  const { selectedLayer } = useSelector((state: RootState) => state.layer)
  const initialValues = {
    title: selectedLayer.title,
    description: selectedLayer.description,
    geometryColor: selectedLayer.geometryColor,
  }
  return <LayerFormModal initalValues={initialValues} steps={steps} />
}
