import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {},
  uploadWrapper: {
    backgroundColor: '#F7F7F7',
    border: '1px solid #E0E0E0',
    borderRadius: 10,
    minHeight: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: '#AAAAAA',
    fontSize: 17,
    marginBottom: theme.spacing(3),
    position: 'relative',
    cursor: 'pointer',
  },
  input: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    opacity: 0,
  },
}))
