import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#eeeeee',
    borderRadius: 10,
    minHeight: theme.spacing(5),
  },
  grouped: {
    border: 'none',
    fontWeight: 'bold',
    color: '#8b8b8b',
    borderRadius: '10px!important',
  },
  small: {
    fontSize: 12,
  },
  active: {
    borderRadius: '10px!important',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
  },
  button: {
    padding: theme.spacing(0.5, 3),
  },
}))
