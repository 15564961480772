import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  title: {
    color: '#000000',
    fontSize: 17,
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
  mailText: {
    color: '#B72D40',
    fontWeight: 'bold',
    fontSize: 20,
    margin: theme.spacing(2, 0),
  },
  root: {
    textAlign: 'center',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textBody: {
    fontSize: 15,
  },
}))
