import { useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { GCheckbox } from 'components'
import { useFormikContext } from 'formik'
import { useStyle } from './FormRow.style'
import { FormRowCheckboxesProps } from './FormRow.interface'

export const FormRowCheckboxes = ({
  rowIndex,
  disableSummary,
  disableTitle,
}: FormRowCheckboxesProps) => {
  const classes = useStyle()
  const { values } = useFormikContext()

  const propKey = useMemo(() => {
    const { jsonSchema } = values as any

    return jsonSchema.properties[rowIndex].propKey
  }, [values, rowIndex])

  return (
    <Grid item xs={3} style={{ display: 'flex' }}>
      <Grid container justify="center">
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
          <GCheckbox
            name="jsonSchema.required"
            style={{ textAlign: 'center' }}
            label={disableTitle || 'حقل اساسى'}
            value={propKey}
            formControlLabelProps={{
              labelPlacement: 'top',
              classes: { label: classes.checkboxLabel },
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
          <GCheckbox
            disabled={disableSummary}
            name={`jsonSchema.properties[${rowIndex}].isSummaryField`}
            style={{ textAlign: 'center' }}
            label={disableTitle || 'إظهار في الملخص'}
            formControlLabelProps={{
              labelPlacement: 'top',
              classes: { label: classes.checkboxLabel },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
