import { DrawerUserProfile } from '@flint/core'
import { ILink } from '@flint/types'
import { Path } from 'global'
import { useLocation, useNavigate } from 'react-router-dom'
import { ClientInfo } from 'components/ClientInfo'
import { DashboardIcon, HowToUseIcon } from '../icons'

export const useLinks = (): ILink[] => {
  const navigate = useNavigate()
  const router = useLocation()

  const buildDynamicProps = (path: Path): Partial<ILink> => {
    return {
      isActive: router.pathname === path,
      link: path,
      onClick: async () => navigate(path),
    }
  }

  const links: any[] = [
    {
      component: DrawerUserProfile,
    },
    {
      component: ClientInfo,
      showOn: ['drawerOpened', 'auth'],
    },
    {
      title: 'الرئيسية',
      IconComponent: DashboardIcon,
      ...buildDynamicProps(Path.DASHBOARD),
    },
    {
      title: 'كيفية إستخدام تتبع',
      IconComponent: HowToUseIcon,
      ...buildDynamicProps(Path.HOW_TO_USE),
    },
  ]

  return links
}
