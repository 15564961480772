import { ReactJsonSchemaForm } from '@flint/rjsf'
import { CreateLayerRequest } from 'global'
import { useFormikContext } from 'formik'
import { useMemo, useState } from 'react'
import { arrPropsToObj } from 'utils/jsonSchema'
import validator from '@rjsf/validator-ajv8'

export const Playground = () => {
  const {
    values: { jsonSchema, webUiJsonSchema },
  } = useFormikContext<CreateLayerRequest>()
  const [formData, setFormData] = useState()

  const schema = useMemo(() => {
    return arrPropsToObj(jsonSchema)
  }, [jsonSchema])

  const uiSchema = useMemo(() => {
    try {
      return JSON.parse(webUiJsonSchema)
    } catch (error) {
      return {}
    }
  }, [webUiJsonSchema])

  return (
    <div>
      <ReactJsonSchemaForm
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        actionButtons={{
          hiddenButtons: ['submit', 'reset'],
        }}
        setFormData={setFormData}
        validator={validator}
      />
    </div>
  )
}
