/* global google, NodeJS */
// @ts-nocheck
import {
  CircularProgress,
  ClickAwayListener,
  TextField,
} from '@material-ui/core'
import clsx from 'clsx'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMap } from 'react-leaflet'
import { geocodeByPlaceId, getLatLng } from 'utils/googleMap'
import L from 'leaflet'
import { useStyle } from './GeoSearchElement.style'
import {
  GeoSearchElementProps,
  POSITION_CLASSES,
  Suggestion,
} from './GeoSearchElement.interface'

class AutocompleteService extends google.maps.places.AutocompleteService {}
const PlacesServiceStatus = google.maps.places.PlacesServiceStatus

let onChangeDebounceTimer: NodeJS.Timeout

export const GeoSearchElement = ({
  position = 'topleft',
}: GeoSearchElementProps) => {
  const map = useMap()
  const [loading, setLoading] = useState(false)
  const [suggestions, setSuggestions] = useState<Suggestion[]>([])
  const [sessionToken, setSessionToken] = useState(null)
  const [zIndexValue, setZIndexValue] = useState<number>(999)

  const [resultActive, setResultActive] = useState(false)
  const [value, setValue] = useState('')
  const rootRef = useRef()
  const inputRef = useRef()
  const classes = useStyle()
  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright

  const autocompleteService = useRef<AutocompleteService>()
  const autocompleteOK = useRef<typeof PlacesServiceStatus.OK>()

  const isResultAcitve = useMemo(() => {
    return !!suggestions.length && resultActive
  }, [suggestions, resultActive])

  useEffect(() => {
    L.DomEvent.disableClickPropagation(inputRef.current)
  }, [inputRef])

  const init = useCallback(() => {
    if (!window.google) {
      throw new Error('Google Maps JavaScript API library must be loaded.')
    }

    if (!window.google.maps.places) {
      throw new Error(
        'Google Maps Places library must be loaded. Please add `libraries=places` to the src URL.'
      )
    }
    autocompleteService.current = new google.maps.places.AutocompleteService()
    autocompleteOK.current = PlacesServiceStatus.OK
  }, [])

  useEffect(() => {
    init()
  }, [init])

  const autocompleteCallback = (
    predictions: google.maps.places.AutocompletePrediction[],
    status: google.maps.places.PlacesServiceStatus
  ) => {
    setLoading(false)
    if (status !== autocompleteOK.current) {
      return
    }
    setResultActive(true)
    setSuggestions(
      predictions.map((p, index) => ({
        id: index,
        description: p.description,
        placeId: p.place_id,
      }))
    )
  }

  const fetchPredictions = () => {
    if (!(value && value.length)) return
    setLoading(true)
    autocompleteService.current.getPlacePredictions(
      {
        input: value,
        sessionToken,
        componentRestrictions: {
          country: 'SA',
        },
      },
      autocompleteCallback
    )
  }

  useEffect(() => {
    try {
      const [lon, lat] = value.split(',').map(Number)
      map.flyTo([lat, lon], 18, { animate: true })
    } catch (_) {
      onChangeDebounce()
    }
  }, [value])

  const onChangeDebounce = () => {
    if (onChangeDebounceTimer) {
      clearTimeout(onChangeDebounceTimer)
    }
    onChangeDebounceTimer = setTimeout(fetchPredictions, 500)
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setValue(value)
  }

  const onSelect = async ({ placeId }: Suggestion) => {
    setResultActive(false)
    try {
      const result = await geocodeByPlaceId(placeId)
      const { lat, lng } = await getLatLng(result[0])
      map.flyTo([lat, lng], 17, { animate: true })
    } catch (error) {
      console.error(error)
    }
  }

  const onClickAwayHanlder = () => {
    setResultActive(false)
  }

  const onFocusHandler = () => {
    const localSessionToken = new google.maps.places.AutocompleteSessionToken()
    setSessionToken(localSessionToken)
    if (suggestions.length) setResultActive(true)
    setZIndexValue(1500)
  }

  const onBlurHandler = () => {
    if (value === '') setZIndexValue(999)
  }

  return (
    <div
      style={{ zIndex: zIndexValue }}
      className={clsx(positionClass, classes.root)}
      ref={rootRef}
    >
      <div className={clsx(classes.control, 'leaflet-control', 'leaflet-bar')}>
        <ClickAwayListener
          onClickAway={onClickAwayHanlder}
          mouseEvent="onClick"
        >
          <div>
            <TextField
              value={value}
              onChange={onChange}
              onFocus={onFocusHandler}
              onBlur={onBlurHandler}
              ref={inputRef}
              margin="dense"
              classes={{ root: classes.input }}
              placeholder="أدخل مدينة أو حي أو معلم للبحث"
              InputProps={{
                endAdornment: loading && <CircularProgress size={20} />,
              }}
              variant="outlined"
            />
            <ul
              className={classes.results}
              style={{ display: isResultAcitve ? 'flex' : 'none' }}
            >
              {suggestions.map((suggestion) => {
                return (
                  <li
                    key={suggestion.id}
                    className="autocomplete-item"
                    onClick={() => onSelect(suggestion)}
                  >
                    <span className="autocomplete-icon icon-localities" />
                    <span className="autocomplete-text">
                      {suggestion.description}
                    </span>
                  </li>
                )
              })}
            </ul>
          </div>
        </ClickAwayListener>
      </div>
    </div>
  )
}
