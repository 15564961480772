import { Dialog, Paper } from '@material-ui/core'
import { LargeImageViewProps } from './GeoTable.interface'

export const LargeImageView = ({
  largeImageView,
  setLargeImageView,
}: LargeImageViewProps) => {
  return (
    <Dialog
      maxWidth="lg"
      open={!!largeImageView}
      onClose={() => setLargeImageView(null)}
    >
      <Paper style={{ padding: 10 }}>
        <img
          src={largeImageView}
          alt="any"
          style={{ margin: 'auto', maxWidth: '100%' }}
        />
      </Paper>
    </Dialog>
  )
}
