export const POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
}
export interface GeoSearchElementProps {
  position: keyof typeof POSITION_CLASSES
}
export interface Suggestion {
  id: number
  description: string
  placeId: string
}
