import { makeStyles } from '@material-ui/core'

export const colors = [
  '#b72d40',
  '#000000',
  '#260081',
  '#B0FF3A',
  '#7700FF',
  '#005677',
  '#FF7010',
  '#DE1DA4',
  '#1DB732',
  '#AFE6FC',
  '#69F9EF',
  '#FFCC23',
  '#FF8214',
  '#1CC1FF',
  '#64580B',
  '#4E04FF',
  '#1DA3D6',
  '#334BF8',
  '#260083',
  '#BE0072',
  '#C92F2F',
]
export const useStyle = makeStyles((theme) => ({
  colorsWrapper: {
    display: 'flex',
    listStyle: 'none',
    flexWrap: 'wrap',
    padding: 0,
  },
  colorItem: {
    minHeight: 50,
    width: 30,
    flexBasis: '10%',
    margin: 5,
    borderRadius: 10,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ontherColorButton: {
    position: 'relative',
    minHeight: 50,
    borderRadius: 10,
    fontWeight: 'bold',
  },
  colorInput: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    left: 0,
    opacity: 0,
  },
  activeColor: {
    boxShadow: theme.shadows[19],
  },
  hide: {
    display: 'none',
  },
}))
