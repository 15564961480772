import {
  createStyles,
  fade,
  InputBase,
  Theme,
  withStyles,
} from '@material-ui/core'

export const GtTextField = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 10,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '16px 12px',
      lineHeight: '25px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: ['Almarai, sans-serif'].join(','),
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.١rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  })
)(InputBase)
