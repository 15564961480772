import { graphQlMutate, graphQlQuery } from '@flint/graphql'
import { getFullToken } from '@flint/auth'
import { graphql, RECORD_PAGE_NUMBER } from 'global'
import { generateRecordsQuery, generateRecordsQueryGtLayers } from 'utils/gql'
import { uploadFilesAndGetFinalData } from '@flint/utils'
import { dataURItoBlob } from 'utils/common'

const {
  FETCH_LAYERS,
  FETCH_REMOTE_LAYERS,
  UPDATE_RECORD,
  CREATE_LAYER_FROM_DATASET,
  UPDATE_LAYER,
  INSERT_RECORDS_FROMDATASET,
  GENERATE_EDA_REPORT,
  LAYER_DETAILS,
  UPLOAD_FILES,
  CREATE_SIGNED_URL,
} = graphql

export async function uploadToSignedURL(signedUrl: string, file: File) {
  try {
    const _headers = new Headers()
    _headers.append('Content-Type', 'application/octet-stream')
    const requestOptions = {
      method: 'PUT',
      headers: _headers,
      body: file,
    }
    const response = await fetch(signedUrl, requestOptions as any)
    if (!response.ok) {
      return {
        error: `Failed to upload: ${response.status} ${response.statusText}`,
      }
    }
    return { error: false }
  } catch (error) {
    return { error }
  }
}

class LayerService {
  // fetch all layers by org id
  async fetch(orgId: number, filters: any = {}) {
    const { data } = await graphQlQuery({
      query: FETCH_LAYERS as any,
      variables: {
        orgId,
        filters,
      },
    })
    return data
  }

  // fetch remote layers by org id
  async fetchRemote(orgId: number, filters: any = {}) {
    const { data } = await graphQlQuery({
      query: FETCH_REMOTE_LAYERS as any,
      variables: {
        orgId,
        filters,
      },
    })
    return data
  }
  // fetch records

  async fetchGtRecords(names: any, bbox: string, zoomLevel: number) {
    const { data } = await graphQlQuery({
      query: generateRecordsQueryGtLayers(names, bbox, zoomLevel),
    })
    return data
  }

  // fetch records
  async fetchRecordsAlias(names: any, bbox: string) {
    const { data } = await graphQlQuery({
      query: generateRecordsQuery(names, bbox),
    })
    return data
  }

  async updateRecord(recordData: any) {
    const { data } = await graphQlMutate({
      mutation: UPDATE_RECORD as any,
      variables: { recordData },
    })
    const { updateRecord } = data
    return updateRecord.record
  }

  async createLayerFromDataset(layerData: any) {
    const { data } = await graphQlMutate({
      mutation: CREATE_LAYER_FROM_DATASET as any,
      variables: { layerData },
    })
    return data
  }

  async updateLayer(id: number, layerData: any) {
    const { data } = await graphQlMutate({
      mutation: UPDATE_LAYER as any,
      variables: { layerData: { ...layerData, id } },
    })
    return data
  }

  async insertRecordsFromDataset(layerId: any, columns?: any) {
    const { data } = await graphQlMutate({
      mutation: INSERT_RECORDS_FROMDATASET as any,
      variables: { layerId, columns },
    })
    return data
  }

  async fetchLayerDetails(layerId: number) {
    const { data } = await graphQlQuery({
      query: LAYER_DETAILS as any,
      variables: { layerId },
    })
    return data.layerDetails
  }

  async generateReport(layerId: number) {
    const { data } = await graphQlMutate({
      mutation: GENERATE_EDA_REPORT as any,
      variables: { layerId },
    })
    return data.generateEdaReport
  }

  async fetchReportDocument(hash: string) {
    const headers = new Headers()
    headers.append('Authorization', getFullToken() as string)
    // Get the report content as text
    const res = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/layers/eda-report/${hash}`, // replace the report hash
      {
        method: 'GET',
        headers,
        mode: 'cors',
      }
    )
    const reportBlob = await res.blob()
    // resolve the response and build object url
    const reportURL = URL.createObjectURL(reportBlob)
    return { reportBlob, reportURL }
  }

  async uploadFile(file: File | Blob, orgId: number, layerId: number) {
    const { data } = await graphQlMutate({
      mutation: UPLOAD_FILES as any,
      variables: {
        context: 'record_field_image',
        // extraArgs: `{"organizationId":${orgId}, "layerId":${layerId}}`,
        extraArgs: JSON.stringify({ layerId, organizationId: orgId }),
        imageFile: file,
      },
    })
    return data.uploadImage.url
  }

  async createSignedURL(
    fileExtension: string,
    organizationId: number,
    layerId: number
  ) {
    try {
      const { data, errors } = await graphQlMutate({
        mutation: CREATE_SIGNED_URL as any,
        variables: { layerId, organizationId, fileExtension },
      })
      const { createRecordAttachmentSignedUrl } = data
      const { blobUrl, signedUrl } = createRecordAttachmentSignedUrl ?? {}
      return { blobUrl, signedUrl, errors }
    } catch (error) {
      console.error(
        'Backend Error: while trying to generate signed url: ',
        error
      )
      return { blobUrl: null, signedUrl: null, errors: error }
    }
  }

  async uploadViaSignedUrl(
    file: File,
    organizationId: number,
    layerId: number,
    fallBack?: (
      file: File,
      organizationId: number,
      layerId: number
    ) => Promise<any>
  ) {
    // request signed url from the backend
    const fileExtension = file.name.split('.').pop()
    const { blobUrl, signedUrl, errors } = await this.createSignedURL(
      fileExtension,
      organizationId,
      layerId
    )
    if (errors) {
      console.error('Error obtaining signed url', errors)
      // try to upload via backend server graphql
      if (fallBack) return fallBack(file, organizationId, layerId)
      else throw errors
    }
    // upload the actual file to the signed url
    const { error } = await uploadToSignedURL(signedUrl, file)
    if (error) {
      console.error('Error uploading using signed url', error)
      // try to upload via backend server graphql
      if (fallBack) return fallBack(file, organizationId, layerId)
      else throw error
    }
    return blobUrl
  }

  async uploadFileAndUpdateData(formData: any, orgId: number, layerId: number) {
    return uploadFilesAndGetFinalData(formData, async (dataURL: any) => {
      // data URL TO file
      const file = dataURItoBlob(dataURL)
      return this.uploadFile(file, orgId, layerId)
    })
  }

  async queryPageNumber(options: {
    layer: number
    limit: number
    record: number
  }): Promise<number> {
    const {
      data: { recordsPagination },
    } = await graphQlQuery({
      query: RECORD_PAGE_NUMBER as any,
      variables: { paginationInput: options },
    })
    return (recordsPagination || {}).pageNumber || null
  }
}

export default new LayerService()
