import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  addIcon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
    color: theme.palette.common.white,
    marginRight: theme.spacing(1),
  },
  addButton: {
    background: '#F6F6F6',
    borderRadius: 10,
  },
}))
