interface INames {
  name: string
  id: number
  alias: string
  orgId?: number
}
export const generateRecordsQuery = (
  names: Array<INames>,
  bbox: string
): any => {
  return `
    query FetchRecordsWithBbox {
      ${
        // generate aials by ids
        names.map(({ id, alias }: INames) => {
          return `${alias}: recordsList(layerId: ${id}, filters: {bbox: "${bbox}"}) {
            ... on PointRecordType {
              id
              createdAt
              data
              geometry
              updatedAt
              layer {
                description
                geometryColor
                geometryType
                id
                name
                jsonSchema
                webUiJsonSchema
                title
              }
            }
            ... on PolygonRecordType {
              id
              data
              createdAt
              geometry
              updatedAt
              layer {
                description
                geometryColor
                geometryType
                id
                name
                jsonSchema
                webUiJsonSchema
                title
              }
            }
            ... on LineStringRecordType {
              id
              createdAt
              data
              geometry
              updatedAt
              layer {
                description
                geometryColor
                geometryType
                id
                name
                jsonSchema
                webUiJsonSchema
                title
              }
            }
            ... on MultiPolygonRecordType {
              id
              createdAt
              data
              geometry
              updatedAt
              layer {
                description
                geometryColor
                geometryType
                id
                name
                jsonSchema
                webUiJsonSchema
                title
              }
            }
            ... on MultiPointRecordType {
              id
              createdAt
              data
              geometry
              updatedAt
              layer {
                description
                geometryColor
                geometryType
                id
                name
                jsonSchema
                webUiJsonSchema
                title
              }
            }
            ... on MultiLineStringRecordType {
              id
              createdAt
              data
              geometry
              updatedAt
              layer {
                description
                geometryColor
                geometryType
                id
                name
                jsonSchema
                webUiJsonSchema
                title
              }
            }
          }
          `
        })
      }
    }
  `
}

export const generateRecordsQueryGtLayers = (
  names: Array<INames>,
  bbox: string,
  zoomLevel: number
): any => {
  return `
    query FetchRecordsWithBbox {
      ${
        // generate aials by ids
        names.map(({ id, alias, orgId }: INames) => {
          const text = `${alias}: geotechRecordsList(layerId: ${id},orgId:${orgId},responseType:GEOJSON, filters: {bbox: "${bbox}",zoomLevel: ${zoomLevel}}) {
            clustered
            records        
          }`
          return text
        })
      }
    }
  `
}
