import React from 'react'
import { HomePage } from 'pages'
import { Routes, Route } from 'react-router-dom'
import { ProtectedRoutes } from './ProtectedRoutes'

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="*" element={<ProtectedRoutes />} />
    </Routes>
  )
}
