import { Grid } from '@material-ui/core'
import { GFInput, GFSelect } from 'components'

import { Fragment } from 'react'
import { FormRowFieldsProps } from './FormRow.interface'

const fieldOptions = [
  {
    text: 'رقم',
    value: 'number',
  },
  { text: 'نص', value: 'string' },
  {
    text: 'ملف',
    value: 'attachment',
  },
  {
    text: 'تاريخ',
    value: 'date',
  },
]

export const FormRowFields = ({
  rowIndex,
  disableTitle,
  disableInteraction,
}: FormRowFieldsProps) => {
  return (
    <Fragment>
      <Grid item xs={3}>
        <GFInput
          name={`jsonSchema.properties[${rowIndex}].propKey`}
          placeholder=" إسم الحقل"
          disabled={disableInteraction}
          label={disableTitle || ' إسم الحقل'}
        />
      </Grid>
      <Grid item xs={3}>
        <GFInput
          name={`jsonSchema.properties[${rowIndex}].title`}
          placeholder="ادخل عنوان الحقل"
          label={disableTitle || 'إدخال عنوان الحقل'}
        />
      </Grid>
      <Grid item xs={3}>
        <GFSelect
          name={`jsonSchema.properties[${rowIndex}].type`}
          placeholder="اختر النوع"
          label={disableTitle || 'اختر النوع'}
          items={fieldOptions as any}
        />
      </Grid>
    </Fragment>
  )
}
