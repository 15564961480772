import React from 'react'
import * as Sentry from '@sentry/react'

import { FlintReduxProvider, AuthenticationProvider } from '@flint/core'
import { FlintApolloProvider } from '@flint/graphql'
import { Path } from 'global'
import { AppWrapper } from 'containers'

function App() {
  return (
    <div className="App">
      <FlintApolloProvider>
        <FlintReduxProvider>
          <AuthenticationProvider successUrl={Path.DASHBOARD}>
            <AppWrapper />
          </AuthenticationProvider>
        </FlintReduxProvider>
      </FlintApolloProvider>
    </div>
  )
}

export default Sentry.withProfiler(App)
