import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    minWidth: 500,
    maxWidth: '60%',
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(2),
    maxHeight: '90vh',
    overflowY: 'auto',
  },
  loaderRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
    height: '100%',
    top: 0,
    right: 0,
    width: '100%',
    zIndex: theme.zIndex.modal,
    backgroundColor: '#eeec',
  },
  hide: {
    display: 'none',
  },
}))
