import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 130,
    right: 9,
    zIndex: theme.zIndex.modal - 300,
  },
}))
