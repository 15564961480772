import { IOrganization } from 'global'

export interface IOrgState {
  orgs: Array<IOrganization>
  activeOrg: IOrganization
  loadingOrgs: boolean
}

export const orgReducer = (): IOrgState =>
  ({
    orgs: [],
  } as any)

export default orgReducer
