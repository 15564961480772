import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  root: {},
  button: {
    margin: theme.spacing(0, 1),
    borderRadius: 15,
  },
  buttonsWrapper: {},
  icon: {
    marginRight: theme.spacing(0.5),
  },
  backWrapper: {
    display: 'flex',
    marginTop: theme.spacing(1),
    justifyContent: 'center',
    textAlign: 'center',
  },
  backIcon: {
    color: theme.palette.primary.main,
  },
  backText: {
    fontWeight: 'bold',
    fontSize: 13,
  },
  hide: {
    display: 'none',
  },
}))
