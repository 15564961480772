import { ReactJsonSchemaForm } from '@flint/rjsf'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchRecords } from 'store/layers'
import { RootState } from 'store/reducer'
import { buildUiSchema } from 'utils/jsonSchema'
import validator from '@rjsf/validator-ajv8'

export const RecordsFilter = () => {
  const [formData, setFormData] = useState<any>(undefined)
  const { selectedLayer } = useSelector((state: RootState) => state.layer)
  const uiSchema =
    selectedLayer &&
    selectedLayer.webUiJsonSchema &&
    buildUiSchema(selectedLayer.jsonSchema, { 'ui:size': 12 })
  const schema =
    (selectedLayer && { ...selectedLayer.jsonSchema, required: [] }) || {}
  const dispatch = useDispatch()

  const onSubmit = ({ formData }: any) => {
    const lookups = Object.keys(formData).map((key: string) => ({
      field: key,
      value: formData[key],
      clause: 'contains',
    }))
    const filters = { lookups }
    if (selectedLayer && Object.keys(selectedLayer).length) {
      dispatch(fetchRecords(selectedLayer, { filters }, true))
    }
    dispatch({
      reducer: 'flint.layout',
      type: 'secondaryDrawerOpened',
      payload: false,
    })
  }
  return (
    <div style={{ maxWidth: 450 }}>
      <ReactJsonSchemaForm
        readonly={false}
        onSubmit={onSubmit}
        formData={formData}
        setFormData={setFormData}
        schema={schema}
        actionButtons={{
          confirmButtonProps: { children: 'إجراء البحث' },
          resetButtonHide: true,
        }}
        uiSchema={uiSchema}
        validator={validator}
      />
    </div>
  )
}
