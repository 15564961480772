import { createStyles, makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) =>
  createStyles({
    root: {
      fontFamily: 'Almarai, sans-serif',
      maxWidth: '100%',
      minWidth: 300,
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
    },
    issues: {
      color: '#ff3444',
      fontSize: 9,
      fontWeight: 'bold',
      zIndex: 44,
      display: 'block',
      marginRight: 'auto',
      backgroundColor: 'rgba(255,0,0,.16)',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      justifyContent: 'space-between',
    },
    titles: {
      display: 'flex',
      alignItems: 'center',
    },
    summary: {
      fontSize: 11,
      textAlign: 'center',
    },
    heading: {
      fontSize: 15,
      fontWeight: 900,
      fontFamily: 'Almarai, sans-serif',
    },
    date: {
      fontSize: 11,
      fontWeight: 'bold',
      marginRight: theme.spacing(1),
      display: 'inline-block',
    },
    footer: {
      marginTop: theme.spacing(2),
      fontFamily: 'Almarai, sans-serif',
      fontSize: 12,
      fontWeight: 900,
      color: theme.palette.secondary.main,
    },
    content: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    clickForDetails: {
      color: '#5D6FCD',
      cursor: 'pointer',
      marginRight: theme.spacing(2),
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    footerButton: {
      margin: theme.spacing(1, 0),
    },
    hide: {
      display: 'none',
    },
  })
)

export default useStyle
