export interface Resource<Payload> {
  read: () => Payload
}

type Status = 'pendding' | 'success' | 'error'

export const createResource = <Payload>(
  asyncFun: () => Promise<Payload>
): Resource<Payload> => {
  let status: Status = 'pendding'
  let result: any
  const promise = asyncFun()
    .then((r: Payload) => {
      status = 'success'
      result = r
    })
    .catch((err: Error) => {
      status = 'error'
      result = err
    })

  return {
    read: (): Payload => {
      switch (status) {
        case 'pendding':
          throw promise
        case 'error':
          throw result
        case 'success':
          return result
        default:
          return null
      }
    },
  }
}

export default createResource
