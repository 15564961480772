import { makeStyles, Theme } from '@material-ui/core'

const useStyle = makeStyles((theme: Theme) => ({
  popupRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  popupAddToLayer: {
    borderRadius: 15,
  },
  popupAnotherButton: {
    borderRadius: 15,
    backgroundColor: '#616060',
    color: theme.palette.common.white,
    margin: theme.spacing(1, 0),
    '&:hover': {
      backgroundColor: '#4a4747',
    },
  },
}))

export default useStyle
