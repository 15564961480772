// @ts-nocheck
import React from 'react'
import { SchemaBuilderViews } from 'global'
import { Box, Tab, Tabs } from '@material-ui/core'
import { BuilderViewsProps } from './BuilderViews.interface'

export const BuilderViews = ({
  isAdvancedSchema,
  tabs,
  activeTab,
  onTabChange,
}: BuilderViewsProps) => {
  const setDisplay = (tab: SchemaBuilderViews) => {
    return tab === activeTab ? 'block' : 'none'
  }

  const setTabDisability = (tab: SchemaBuilderViews) => {
    return tab === SchemaBuilderViews.UI_VIEW && isAdvancedSchema
  }

  return (
    <div>
      <Tabs
        value={activeTab}
        onChange={(_, value) => {
          onTabChange(value)
        }}
        textColor="primary"
        indicatorColor="primary"
      >
        {tabs.map(({ label, tab }) => (
          <Tab
            value={tab}
            label={label}
            key={tab}
            disabled={setTabDisability(tab)}
          />
        ))}
      </Tabs>
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        {tabs.map(({ tab, Component }) => (
          <Box display={setDisplay(tab)} key={tab}>
            <Component editorView={tab} />
          </Box>
        ))}
      </div>
    </div>
  )
}
